// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp, configureCompat } from 'vue';
import Popper from 'vue3-popper';
import '@omg-technology-shared-resources/omg-technology-styles';
import '@omg-technology-shared-resources/omg-technology-styles/dist/omg-technology.css';
import http from '@/api/http';
import PageHeader from '@/layouts/PageHeader';
import TableContainer from '@/layouts/TableContainer';
import BasicLayout from '@/layouts/BasicLayout';
import TabbedLayout from '@/layouts/TabbedLayout';
import IconButton from '@/components/FormControls/IconButton';
import store from './store';
import App from './App';
import router from './router';

configureCompat({
  COMPONENT_V_MODEL: false,
  WATCH_ARRAY: false,
  ATTR_FALSE_VALUE: false,
});

const app = createApp(App).use(store);
app.config.globalProperties.$http = http;
app.config.globalProperties.$store = store;
store.$http = http;

app.use(router);
app.use(Popper);
app.component('table-container', TableContainer);
app.component('page-header', PageHeader);
app.component('basic-layout', BasicLayout);
app.component('tabbed-layout', TabbedLayout);
app.component('icon-button', IconButton);
app.mount('#app');
