/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import alphabetizeByKey from '@/utilities/alphabetizeByKey';

export default {
  namespaced: true,
  state: {
    all: [],
    currentDatasource: {},
    uploadData: {},
  },
  getters: {},
  actions: {

    list({ commit, dispatch }) {
      const vm = this;
      const url = 'datasources?fields=id,schema,name,bucket,path,status,updatedAt,updatedBy,dataRefreshedAt,error,file_type,glueDatabase,glueTable,redshiftSchema,redshiftTable,pipeline,starburstDataProduct,starburstDataSet';
      const responsDatasources = [];
      commit('progress/setProcessing', url, { root: true });
      const getData = pageUrl => vm.$http.get(pageUrl).then((response) => {
        responsDatasources.push(...response.data.items);
        if (response.data.next) {
          return getData(response.data.next);
        }
        commit('progress/removeProcessing', url, { root: true });
        commit('setDatasources', responsDatasources);
        return responsDatasources;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
      return getData(url);
    },

    create({ dispatch, commit }, data) {
      const vm = this;
      const url = 'datasources';
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url, data).then((response) => {
        dispatch('progress/setSuccessMessage', 'Successfully created data source', { root: true });
        dispatch('list');
        commit('progress/removeProcessing', url, { root: true });
        return response.data;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    read({ commit, dispatch }, id) {
      const vm = this;
      const url = `datasources/${id}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((response) => {
        const datasource = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentDatasource', datasource);
        return datasource;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    updateCurrentDatasource({ state, commit, dispatch }) {
      const vm = this;
      const url = `datasources/${state.currentDatasource.id}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.put(url, state.currentDatasource).then((response) => {
        const datasource = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentDatasource', datasource);
        return datasource;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    reprocessDatasource({ state, commit, dispatch }, id) {
      dispatch('read', id).then(() => {
        const data = { ...state.currentDatasource };
        data.status = 'started';
        data.error = null;
        commit('setCurrentDatasource', data);
        dispatch('updateCurrentDatasource').then(() => {
          dispatch('list');
        });
      });
    },

    generateUploadUrl({ commit, dispatch }, filename) {
      const vm = this;
      const url = `upload/${filename}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url).then((response) => {
        const uploadData = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setUploadData', uploadData);
        return uploadData;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

  },
  mutations: {
    setDatasources(state, datasources) {
      state.all = datasources.sort(alphabetizeByKey.bind(null, { key: 'name', desc: false }));
    },
    setCurrentDatasource(state, datasource) {
      state.currentDatasource = datasource;
    },
    setUploadData(state, uploadData) {
      state.uploadData = uploadData;
    },
  },
};
