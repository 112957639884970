const permissions = {
  methods: {
    hasPermission(permission) {
      const vm = this;
      return vm.$store.getters['user/permissions'].map(x => x.name).indexOf(permission) > -1;
    },
    hasAnyPermission(perms) {
      const vm = this;
      for (let i = 0; i < perms.length; i += 1) {
        const permission = perms[i];
        if (vm.hasPermission(permission)) {
          return true;
        }
      }
      return false;
    },
    hasValuesForPermission(permissionName, values) {
      const vm = this;
      const userPermissions = vm.$store.getters['user/permissions'];
      const user = vm.$store.state.user.user;
      const permission = userPermissions.find(x => x.name === permissionName);
      if (!permission) {
        return false;
      }
      if (permission.data_profiles) {
        return permission.data_profiles.filter(x => vm.hasAllValues(x, values)).length > 0;
      }
      return vm.hasAllValues(user, values);
    },
    hasAllValues(item, values) {
      const vm = this;
      for (let i = 0; i < values.length; i += 1) {
        const entity = values[i];
        if (!vm.hasValue(item, entity)) {
          return false;
        }
      }
      return true;
    },
    hasValue(item, entity) {
      return (
        item.data_mappings[entity.entity]
        && (
          item.data_mappings[entity.entity] === '*'
          || item.data_mappings[entity.entity].indexOf(entity.value) > -1
        )
      );
    },
  },
};
export default permissions;
