/* eslint-disable object-curly-newline */
import axios from 'axios';

const HTTP = accessToken => axios.create({
  baseURL: process.env.VUE_APP_PROTEGO_API_ROOT,
  headers: {
    Authorization: accessToken,
  },
});

export default {
  get(config, store) {
    const state = store.rootState;
    let url = `/${config.endpoint}/${config.id}`;
    if (config.environment && config.environment !== 'Production') {
      url += `?environment=${config.environment}`;
    }
    return HTTP(state.auth.accessToken).get(url)
      .then(response => response.data)
      .catch(error => error);
  },

  getAppUsers(config, store) {
    const state = store.rootState;
    let url = `/applications/${config.id}/users`;
    if (config.environment && config.environment !== 'Production') {
      url += `?environment=${config.environment}`;
    }
    return HTTP(state.auth.accessToken).get(url)
      .then(response => response.data)
      .catch(error => error);
  },
};
