import store from '@/store/index';

const syncRoutes = [
  {
    path: '/sync',
    name: 'Sync',
    component: () => import('@/views/Sync/Configs'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'Sync',
          title: 'Sync Configuration',
        },
      ],
      auth: {
        permission: 'create_sync-configuration',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('sync/list', {}),
        store.dispatch('datasources/list'),
        store.dispatch('entities/list'),
      ];
      Promise.all(promises).then(() => {
        next();
      });
    },
  },
  {
    path: '/sync/:entity',
    name: 'SyncErrors',
    component: () => import('@/views/Sync/Errors'),
    props: true,
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'Sync',
          title: 'Sync Configuration',
        },
        {
          name: 'SyncErrors',
          title: 'Sync Errors',
        },
      ],
      auth: {
        permission: 'create_sync-configuration',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('syncerrors/list', to.params.entity),
      ];
      Promise.all(promises).then(() => {
        next();
      });
    },
  },
  {
    path: '/activations',
    name: 'Activations Sync',
    component: () => import('@/views/Activations/Index'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'Activations Sync',
          title: 'Activations Configuration',
        },
      ],
      auth: {
        permission: 'create_sync-configuration',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('activationsync/list', {}),
        store.dispatch('datasources/list'),
        store.dispatch('entities/list'),
      ];
      Promise.all(promises).then(() => {
        next();
      });
    },
  },
  {
    path: '/activations/:id',
    name: 'ActivationsSyncErrors',
    component: () => import('@/views/Activations/Errors'),
    props: true,
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'Activations Sync',
          title: 'Activations Configuration',
        },
      ],
      auth: {
        permission: 'create_sync-configuration',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('activationssyncerrors/list', to.params.id),
      ];
      Promise.all(promises).then(() => {
        next();
      });
    },
  },
];

export default syncRoutes;
