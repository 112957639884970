/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import alphabetizeByKey from '@/utilities/alphabetizeByKey';

export default {
  namespaced: true,
  state: {
    all: [],
    currentDataset: {},
    uploadData: {},
  },
  getters: {},
  actions: {

    list({ commit, dispatch }) {
      const vm = this;
      const url = 'datasets?fields=id,schema,name,bucket,path,status,updatedAt,updatedBy,dataRefreshedAt,error';
      const responsDatasets = [];
      commit('progress/setProcessing', url, { root: true });
      const getData = (pageUrl) => {
        vm.$http.get(pageUrl).then((response) => {
          responsDatasets.push(...response.data.items);
          if (response.data.next) {
            return getData(response.data.next);
          }
          commit('progress/removeProcessing', url, { root: true });
          commit('setDatasets', responsDatasets);
          return responsDatasets;
        }, (error) => {
          dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
          commit('progress/removeProcessing', url, { root: true });
          return error.response.data.error.message;
        });
      };
      return getData(url);
    },

    create({ dispatch, commit }, data) {
      const vm = this;
      const url = 'datasets';
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url, data).then((response) => {
        dispatch('progress/setSuccessMessage', 'Successfully created data set', { root: true });
        dispatch('list');
        commit('progress/removeProcessing', url, { root: true });
        return response.data;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    read({ commit, dispatch }, id) {
      const vm = this;
      const url = `datasets/${id}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((response) => {
        const dataset = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentDataset', dataset);
        return dataset;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    updateCurrentDataset({ state, commit, dispatch }) {
      const vm = this;
      const url = `datasets/${state.currentDataset.id}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.put(url, state.currentDataset).then((response) => {
        const dataset = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentDataset', dataset);
        return dataset;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    reprocessDataset({ state, commit, dispatch }, id) {
      dispatch('read', id).then(() => {
        const data = { ...state.currentDataset };
        data.status = 'started';
        data.error = null;
        commit('setCurrentDataset', data);
        dispatch('updateCurrentDataset').then(() => {
          dispatch('list');
        });
      });
    },

    generateUploadUrl({ commit, dispatch }, filename) {
      const vm = this;
      const url = `upload/${filename}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url).then((response) => {
        const uploadData = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setUploadData', uploadData);
        return uploadData;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

  },
  mutations: {
    setDatasets(state, datasets) {
      state.all = datasets.sort(alphabetizeByKey.bind(null, { key: 'name', desc: false }));
    },
    setCurrentDataset(state, dataset) {
      state.currentDataset = dataset;
    },
    setUploadData(state, uploadData) {
      state.uploadData = uploadData;
    },
  },
};
