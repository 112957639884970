import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/routes';
import store from '@/store';
import SecurityCheck from './securityCheck';

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  const securityCheck = new SecurityCheck(to);
  store.commit('progress/setProcessing', 'authenticating');
  securityCheck.authenticate().then(() => {
    securityCheck.authorize().then((authorized) => {
      store.commit('progress/removeProcessing', 'authenticating');
      if (authorized) {
        next();
      } else {
        next({
          name: to.meta?.auth?.redirect || 'AccessDenied',
        });
      }
    });
  });
});

export default router;
