/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */

export default {
  namespaced: true,
  state: {
    schemasLoaded: false,
    tablesLoaded: false,
    tables: [],
    schemas: [],
    currentSchema: null,
  },
  getters: {},
  actions: {
    list({ commit }, { offset }) {
      const vm = this;
      let url = 'rsschemas';
      if (offset) {
        url = `${url}?offset=${offset}`;
      } else {
        commit('setSchemasLoaded', false);
      }
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        if (!offset) {
          commit('setSchemas', data.data.items);
        } else {
          commit('appendSchemas', data.data.items);
        }
        commit('setSchemasLoaded', true);
        return data;
      }, error => error);
    },
    getTables({ commit, state }, { schema, offset }) {
      const vm = this;
      commit('setCurrentSchema', schema);
      let url = `rsschemas/${schema}/tables`;
      if (offset) {
        url = `${url}?offset=${offset}`;
      } else {
        commit('setTablesLoaded', false);
        commit('setTables', []);
      }
      const loadData = (pageUrl, firstPage) => vm.$http.get(pageUrl).then((data) => {
        if (schema === state.currentSchema) {
          if (firstPage) {
            commit('setTables', data.data.items);
          } else {
            commit('appendTables', data.data.items);
          }
          commit('setTablesLoaded', true);
          if (data.data.next) {
            loadData(data.data.next, false);
          }
        }
        return data;
      }, error => error);
      return loadData(url);
    },
  },
  mutations: {
    setCurrentSchema(state, payload) {
      state.currentSchema = payload;
    },
    setSchemas(state, payload) {
      state.schemas = payload;
    },
    setSchemasLoaded(state, payload) {
      state.schemasLoaded = payload;
    },
    appendSchemas(state, payload) {
      state.schemas.push(...payload);
    },
    setTables(state, payload) {
      state.tables = payload;
    },
    setTablesLoaded(state, payload) {
      state.tablesLoaded = payload;
    },
    appendTables(state, payload) {
      state.tables.push(...payload);
    },
  },
};
