/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import alphabetizeByKey from '@/utilities/alphabetizeByKey';

export default {
  namespaced: true,
  state: {
    Entities: [],
    Fetching: false,
    refreshedAt: null,
    ActiveEntity: {
      description: null,
      fields: [],
      id: null,
      layout: [],
      name: null,
      owners: [],
    },
  },
  getters: {},
  actions: {
    list({ state, commit }, forceRefresh) {
      const vm = this;
      const refreshedMoment = dayjs(state.refreshedAt);
      if (
        (
          forceRefresh
          || (
            !state.refreshedAt
            || refreshedMoment.add(10, 'minutes').isBefore(dayjs())
          )
        )
        && !state.Fetching
      ) {
        commit('setFetching', true);
        commit('progress/setProcessing', 'entities', { root: true });
        let request = vm.$http.get('entities');
        if (forceRefresh) {
          request = vm.$http.get('entities', { headers: { 'Cache-Control': 'max-age=0' } });
        }
        return request.then((data) => {
          commit('progress/removeProcessing', 'entities', { root: true });
          commit('setEntityNames', data.data.items);
          commit('setFetching', false);
          return data;
        }, error => error);
      }
      return new Promise(resolve => resolve(state.Entities));
    },

    get({ commit }, entity) {
      const vm = this;
      const url = `entities/${entity}`;
      commit('setFetching', true);
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        commit('setActiveEntity', data.data);
        commit('setFetching', false);
        return data;
      }, error => error);
    },

    handleEntityResponse({ dispatch, commit }, config) {
      commit('progress/removeProcessing', config.url, { root: true });
      dispatch('progress/setSuccessMessage', config.successString, { root: true });
      commit('setRefreshed', null);
      dispatch('list', true);
      return config.response.data;
    },

    handleErrorResponse({ dispatch, commit }, config) {
      commit('progress/removeProcessing', config.url, { root: true });
      let error = null;
      if (config.response.error) {
        error = config.response.error.message;
      }
      if (error) {
        dispatch('progress/setErrorMessage', `${config.errorString} ${error}`, { root: true });
        return null;
      }
      dispatch('progress/setErrorMessage', config.errorString, { root: true });
      return null;
    },

    update({ commit, dispatch }, data) {
      const vm = this;
      const url = `entities/${data.existingName}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.put(url, data.data).then(response => dispatch('handleEntityResponse', {
        response,
        successString: 'Successfully updated entity',
        url,
      }), response => dispatch('handleErrorResponse', {
        response: response.response.data,
        errorString: 'Update failed with the following error:',
        url,
      }));
    },

    setDeactivated({ commit, dispatch }, data) {
      const vm = this;
      const url = `entities/${data.entity}/deactivated`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url, {
        deactivated: data.deactivated,
      }).then(response => dispatch('handleEntityResponse', {
        response,
        successString: 'Successfully updated entity',
        url,
      }), response => dispatch('handleErrorResponse', {
        response: response.response.data,
        errorString: 'Update failed with the following error:',
        url,
      }));
    },

    delete({ commit, dispatch }, entity) {
      const vm = this;
      const url = `entities/${entity}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.delete(url).then(response => dispatch('handleEntityResponse', {
        response,
        successString: 'Successfully deleted entity',
        url,
      }), response => dispatch('handleErrorResponse', {
        response: response.response.data,
        errorString: 'Update failed with the following error:',
        url,
      }));
    },

    create({ commit, dispatch }, data) {
      const vm = this;
      const url = 'entities';
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url, data).then(response => dispatch('handleEntityResponse', {
        response,
        successString: 'Successfully created entity',
        url,
      }), response => dispatch('handleErrorResponse', {
        response: response.response.data,
        errorString: 'Update failed with the following error:',
        url,
      }));
    },
  },
  mutations: {
    setRefreshed(state, payload) {
      state.refreshedAt = payload;
    },

    setEntityNames(state, payload) {
      state.Entities = payload.sort(alphabetizeByKey.bind(null, 'name'));
      state.refreshedAt = new Date();
    },

    setFetching(state, payload) {
      state.Fetching = payload;
    },

    setActiveEntity(state, payload) {
      state.ActiveEntity = payload;
    },
  },
};
