/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */

export default {
  namespaced: true,
  state: {
    DataProductsLoaded: false,
    DataSetsLoaded: false,
    DataSets: [],
    DataProducts: [],
    CurrentDataProduct: null,
  },
  getters: {},
  actions: {
    list({ commit }) {
      const vm = this;
      const url = 'dataproducts';
      commit('setDataProductsLoaded', false);
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        commit('setDataProducts', data.data.items);
        commit('setDataProductsLoaded', true);
        return data;
      }, error => error);
    },
    getDataSets({ commit, state }, { product }) {
      const vm = this;
      commit('setCurrentDataProduct', product);
      const url = `dataproducts/${product}/datasets`;
      commit('setDataSetsLoaded', false);
      commit('setDataSets', []);
      commit('progress/setProcessing', url, { root: true });
      const loadData = (pageUrl, firstPage) => vm.$http.get(pageUrl).then((data) => {
        if (product === state.CurrentDataProduct) {
          if (firstPage) {
            commit('setDataSets', data.data.items);
          } else {
            commit('appendDataSets', data.data.items);
          }
          commit('setDataSetsLoaded', true);
          if (data.data.next) {
            loadData(data.data.next, false);
          } else {
            commit('progress/removeProcessing', url, { root: true });
          }
        }
        return data;
      }, error => error);
      return loadData(url);
    },
  },
  mutations: {
    setCurrentDataProduct(state, payload) {
      state.CurrentDataProduct = payload;
    },
    setDataProducts(state, payload) {
      state.DataProducts = payload;
    },
    setDataProductsLoaded(state, payload) {
      state.DataProductsLoaded = payload;
    },
    appendDataProducts(state, payload) {
      state.DataProducts.push(...payload);
    },
    setDataSets(state, payload) {
      state.DataSets = payload;
    },
    setDataSetsLoaded(state, payload) {
      state.DataSetsLoaded = payload;
    },
    appendDataSets(state, payload) {
      state.DataSets.push(...payload);
    },
  },
};
