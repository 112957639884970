import store from '@/store';

const duplicatesRoutes = [
  {
    path: '/duplicates',
    name: 'Duplicates',
    component: () => import('@/views/Duplicates/Index'),
    meta: {
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'Duplicates',
          title: 'Duplicates',
        },
      ],
      auth: {
        permission: 'read_record',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('entities/list'),
        store.dispatch('duplicates/list'),
      ];
      Promise.all(promises).then(() => {
        next();
      });
    },
  },
];

export default duplicatesRoutes;
