const reportingRoutes = [
  {
    path: '/reporting',
    name: 'Reporting',
    component: () => import('@/views/Reporting/Index'),
    meta: {
      auth: {
        permission: 'read_reporting',
        redirect: 'AccessDenied',
      },
      breadcrumbTrail: [
        {
          name: 'Reporting',
          title: 'Reporting',
        },
      ],
    },
  },
];

export default reportingRoutes;
