export default {
  activatedValues: {},
  fields: {},
  name: null,
  relatedEntities: [],
  relatedEntityValues: {},
  owner: null,
  aliases: {},
  entity: null,
};
