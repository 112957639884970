/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
const agencyCode = process.env.VUE_APP_MEDIAOCEAN_AGENCY_CODE;

export default {
  namespaced: true,
  state: {
    clientCodesLoaded: false,
    clientCodes: [],
    advertisers: [],
    advertisersLoaded: false,
  },
  getters: {},
  actions: {
    listClientCodes({ commit }, codes) {
      const vm = this;
      let url = `mediaocean/${agencyCode}/clientcodes`;
      if (codes?.length) {
        url += `?clientCode=${codes.join(',')}`;
      }
      commit('setClientCodesLoaded', false);
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        commit('setClientCodes', data.data.items);
        commit('setClientCodesLoaded', true);
        return data;
      }, (error) => {
        commit('progress/removeProcessing', url, { root: true });
        return error;
      });
    },
    searchAdvertisers({ commit }, search) {
      const vm = this;
      const url = `mediaocean/${agencyCode}/advertisers?query=${search}`;
      commit('progress/setProcessing', url, { root: true });
      commit('setAdvertisersLoaded', false);
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        commit('setAdvertisers', data.data.items);
        commit('setAdvertisersLoaded', true);
        return data;
      }, (error) => {
        commit('progress/removeProcessing', url, { root: true });
        return error;
      });
    },
    mapClientCodes({ commit, rootState }, payload) {
      const vm = this;
      const url = `/entities/${rootState.entities.ActiveEntity.name}/records/${rootState.records.Record.id}/mapping/${agencyCode}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.put(url, payload).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        return data;
      }, (error) => {
        commit('progress/removeProcessing', url, { root: true });
        return error;
      });
    },
    deleteClientCodes({ commit, rootState }) {
      const vm = this;
      const url = `/entities/${rootState.entities.ActiveEntity.name}/records/${rootState.records.Record.id}/mapping/${agencyCode}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.delete(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        return data;
      }, (error) => {
        commit('progress/removeProcessing', url, { root: true });
        return error;
      });
    },
    createAdvertiser({ commit }, payload) {
      const vm = this;
      const url = `mediaocean/${agencyCode}/advertisers`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.put(url, payload).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        commit('pushAdvertiser', data.data);
        return data;
      }, (error) => {
        commit('progress/removeProcessing', url, { root: true });
        commit('progress/setError', error.response.data.error.message, { root: true });
        return error;
      });
    },
  },
  mutations: {
    setClientCodes(state, payload) {
      state.clientCodes = payload;
    },
    setClientCodesLoaded(state, payload) {
      state.clientCodesLoaded = payload;
    },
    setAdvertisers(state, payload) {
      state.advertisers = payload;
    },
    setAdvertisersLoaded(state, payload) {
      state.advertisersLoaded = payload;
    },
    pushAdvertiser(state, payload) {
      state.advertisers.push(payload);
      state.advertisersLoaded = true;
    },
  },
};
