/* eslint-disable object-curly-newline */
import dayjs from 'dayjs';
import endpoints from '../../api/protego';

const options = {
  endpoint: 'applications',
  environment: process.env.VUE_APP_ENVIRONMENT,
};

export default {
  namespaced: true,
  state: {
    application: null,
    refreshedAt: null,
  },
  getters: {},
  actions: {
    getFromApi(context, id) {
      options.id = id;
      return endpoints.get(options, context).then(response => response);
    },

    get({ state, dispatch, commit }, id) {
      const refreshedMoment = dayjs(state.refreshedAt);
      if (
        !state.application
        || state.application.id !== id
        || !state.refreshedAt
        || refreshedMoment.add(10, 'minutes').isBefore(dayjs())
      ) {
        return dispatch('getFromApi', id).then((application) => {
          const loadedApplication = application;
          commit('setApplication', loadedApplication);
          return loadedApplication;
        });
      }
      return new Promise(resolve => resolve(state.application));
    },

  },
  mutations: {
    setApplication(state, application) {
      state.application = application;
      if (application === null) {
        state.refreshedAt = null;
      } else {
        state.refreshedAt = new Date();
      }
    },
  },
};
