<template>
  <div>
    <div v-if="hasHeaderControls" class="row header-controls">
      <div class="col my-4">
        <slot name="header-controls"></slot>
      </div>
    </div>
    <div class="row align-items-end" v-else>
      <div class="col-12 my-4 py-2">
        <h1 class="mb-0" v-if="title">{{title}}</h1>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 gray-bg px-1 px-md-5">
        <slot></slot>
      </div>
      <div class="col-12 mt-4">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.header-controls {
  :deep(.btn-primary) {
    border: 1px solid rgba(255, 255, 255, .5);
  }
}
</style>
<script>
export default {
  computed: {
    hasHeaderControls() {
      return !!this.$slots['header-controls'];
    },
  },
  props: {
    title: {
      type: String,
      required: false,
    },
  },
};
</script>
