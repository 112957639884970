import store from '@/store/index';

const referenceDataRoutes = [
  {
    path: '/referencedata',
    name: 'ReferenceData',
    component: () => import('@/views/ReferenceData/Index'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'ReferenceData',
          title: 'Reference Data',
        },
      ],
    },
  },
  {
    path: '/referencedata/referencetables',
    name: 'ReferenceTables',
    component: () => import('@/views/ReferenceTables/Index'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'ReferenceData',
          title: 'Reference Data',
        },
        {
          name: 'ReferenceTables',
          title: 'Reference Tables',
        },
      ],
      auth: {
        permission: 'read_reference-table',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('entities/list'),
        store.dispatch('referencetables/listAllReferenceTables'),
        store.dispatch('datasets/list'),
      ];
      Promise.all(promises).then(() => {
        next();
      });
    },
  },
  {
    path: '/referencedata/referencetables/create',
    name: 'CreateReferenceTable',
    component: () => import('@/views/ReferenceTables/Create'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'ReferenceData',
          title: 'Reference Data',
        },
        {
          name: 'ReferenceTables',
          title: 'Reference Tables',
        },
        {
          name: 'CreateReferenceTable',
          title: 'Create',
        },
      ],
      auth: {
        permission: 'create_reference-table',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('entities/list'),
        store.dispatch('datasets/list'),
      ];
      Promise.all(promises).then(() => {
        next();
      });
    },
  },
  {
    path: '/referencedata/referencetables/:id',
    name: 'UpdateReferenceTable',
    component: () => import('@/views/ReferenceTables/Update'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'ReferenceData',
          title: 'Reference Data',
        },
        {
          name: 'ReferenceTables',
          title: 'Reference Tables',
        },
        {
          name: 'UpdateReferenceTable',
          title: null,
        },
      ],
      auth: {
        permission: 'update_reference-table',
        redirect: 'AccessDenied',
      },
    },
    props: true,
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('entities/list'),
        store.dispatch('datasets/list'),
        store.dispatch('referencetables/read', to.params.id),
      ];
      const destination = to;
      Promise.all(promises).then((responses) => {
        destination.meta.breadcrumbTrail[2].title = responses[2].name;
        next();
      });
    },
  },
  {
    path: '/referencedata/referencetables/:id/schedule',
    name: 'ScheduleReferenceTable',
    component: () => import('@/views/ReferenceTables/Schedule'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'ReferenceData',
          title: 'Reference Data',
        },
        {
          name: 'ReferenceTables',
          title: 'Reference Tables',
        },
        {
          name: 'UpdateReferenceTable',
          title: null,
        },
        {
          name: 'ScheduleReferenceTable',
          title: 'Schedule',
        },
      ],
      auth: {
        permission: 'update_reference-table',
        redirect: 'AccessDenied',
      },
    },
    props: true,
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('referencetables/read', to.params.id),
        store.dispatch('referencetables/readSchedule', to.params.id),
      ];
      const destination = to;
      Promise.all(promises).then((responses) => {
        destination.meta.breadcrumbTrail[2].title = responses[0].name;
        next();
      });
    },
  },
  {
    path: '/referencedata/supertables',
    name: 'SuperTables',
    component: () => import('@/views/SuperTables/Index'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'ReferenceData',
          title: 'Reference Data',
        },
        {
          name: 'SuperTables',
          title: 'Super Tables',
        },
      ],
      auth: {
        permission: 'read_reference-table',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('entities/list'),
        store.dispatch('supertables/list'),
      ];
      Promise.all(promises).then(() => {
        next();
      });
    },
  },
  {
    path: '/referencedata/supertables/create',
    name: 'CreateSuperTable',
    component: () => import('@/views/SuperTables/Create'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'ReferenceData',
          title: 'Reference Data',
        },
        {
          name: 'SuperTables',
          title: 'Super Tables',
        },
        {
          name: 'CreateSuperTable',
          title: 'Create',
        },
      ],
      auth: {
        permission: 'create_reference-table',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('entities/list').then(() => {
        next();
      });
    },
  },
  {
    path: '/referencedata/supertables/:id',
    name: 'ReadSuperTable',
    component: () => import('@/views/SuperTables/Read'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'ReferenceData',
          title: 'Reference Data',
        },
        {
          name: 'SuperTables',
          title: 'Super Tables',
        },
        {
          name: 'ReadSuperTable',
          title: null,
        },
      ],
      auth: {
        permission: 'read_reference-table',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('entities/list'),
        store.dispatch('supertables/read', to.params.id),
      ];
      const destination = to;
      Promise.all(promises).then((responses) => {
        destination.meta.breadcrumbTrail[2].title = responses[1].name;
        next();
      });
    },
  },
  {
    path: '/referencedata/supertables/:id/edit',
    name: 'UpdateSuperTable',
    component: () => import('@/views/SuperTables/Update'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'ReferenceData',
          title: 'Reference Data',
        },
        {
          name: 'SuperTables',
          title: 'Super Tables',
        },
        {
          name: 'ReadSuperTable',
          title: null,
        },
        {
          name: 'UpdateSuperTable',
          title: 'Edit',
        },
      ],
      auth: {
        permission: 'update_reference-table',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('entities/list'),
        store.dispatch('supertables/read', to.params.id),
      ];
      const destination = to;
      Promise.all(promises).then((responses) => {
        destination.meta.breadcrumbTrail[2].title = responses[1].name;
        next();
      });
    },
  },
  {
    path: '/referencedata/supertables/:id/schedule',
    name: 'ScheduleSuperTable',
    component: () => import('@/views/SuperTables/Schedule'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'ReferenceData',
          title: 'Reference Data',
        },
        {
          name: 'SuperTables',
          title: 'Super Tables',
        },
        {
          name: 'ReadSuperTable',
          title: null,
        },
        {
          name: 'UpdateSuperTable',
          title: 'Edit',
        },
      ],
      auth: {
        permission: 'update_reference-table',
        redirect: 'AccessDenied',
      },
    },
    props: true,
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('supertables/read', to.params.id),
        store.dispatch('referencetables/readSchedule', to.params.id),
      ];
      const destination = to;
      Promise.all(promises).then((responses) => {
        destination.meta.breadcrumbTrail[2].title = responses[0].name;
        next();
      });
    },
  },
  {
    path: '/referencedata/datasets',
    name: 'DataSets',
    component: () => import('@/views/DataSets/Index'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'ReferenceData',
          title: 'Reference Data',
        },
        {
          name: 'DataSets',
          title: 'Data Sets',
        },
      ],
      auth: {
        permission: 'read_data-set',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('datasets/list');
      next();
    },
  },
  {
    path: '/referencedata/datasets/create',
    name: 'CreateDataSet',
    component: () => import('@/views/DataSets/Create'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'ReferenceData',
          title: 'Reference Data',
        },
        {
          name: 'DataSets',
          title: 'Data Sets',
        },
        {
          name: 'CreateDataSet',
          title: 'Create',
        },
      ],
      auth: {
        permission: 'create_data-set',
        redirect: 'AccessDenied',
      },
    },
  },
  {
    path: '/referencedata/datasets/:id',
    name: 'DataSet',
    component: () => import('@/views/DataSets/Read'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'ReferenceData',
          title: 'Reference Data',
        },
        {
          name: 'DataSets',
          title: 'Data Sets',
        },
        {
          name: 'DataSet',
          title: 'Update',
        },
      ],
      auth: {
        permission: 'read_data-set',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const destination = to;
      store.dispatch('referencetables/list', to.params.id);
      store.dispatch('datasets/read', to.params.id).then((dataset) => {
        destination.meta.breadcrumbTrail[2].title = dataset.name;
        next();
      });
    },
  },
];

export default referenceDataRoutes;
