/* eslint-disable object-curly-newline */
import menuItems from '@/menuitems';

const getPermissions = (rootGetters) => {
  const permissions = [];
  if (rootGetters.permissions) {
    const permissionsKeys = Object.keys(rootGetters.permissions);
    for (let i = 0; i < permissionsKeys.length; i += 1) {
      permissions.push(...rootGetters.permissions[permissionsKeys[i]]);
    }
  }
  if (rootGetters['user/permissions']) {
    permissions.push(...rootGetters['user/permissions']);
  }
  return permissions;
};

const hasAnyPermission = (permissions, userPermissions) => {
  for (let i = 0; i < permissions.length; i += 1) {
    const permission = permissions[i];
    if (userPermissions.map(x => x.name).indexOf(permission) > -1) {
      return true;
    }
  }
  return false;
};

const filterMenuItem = (item, permissions) => {
  const menuItem = item;
  if (menuItem.subMenuItems) {
    menuItem.subMenuItems = menuItem.subMenuItems.filter(x => (
      !x.permissions
      || hasAnyPermission(x.permissions, permissions)
    ));
    if (menuItem.subMenuItems.length === 0) {
      return null;
    }
  } else if (!!menuItem.permissions && (
    !hasAnyPermission(menuItem.permissions, permissions)
  )) {
    return null;
  }
  return menuItem;
};

const setMenuItems = (providedItems, userPermissions) => {
  const items = [];
  for (let i = 0; i < providedItems.length; i += 1) {
    const item = { ...providedItems[i] };
    const cleansedItem = filterMenuItem(item, userPermissions);
    if (cleansedItem !== null) {
      items.push(cleansedItem);
    }
  }
  return items;
};

export default {
  namespaced: true,
  state: {
    menuItems,
  },
  getters: {
    permittedMenuItems: (state, getters, rootState, rootGetters) => {
      const permissions = getPermissions(rootGetters);
      return setMenuItems(state.menuItems, permissions);
    },
  },
  actions: {},
  mutations: {
    setMenuItems(st, value) {
      // eslint-disable-next-line no-param-reassign
      st.menuItems = value;
    },
  },
};
