import store from '@/store';

const quarantineRoutes = [
  {
    path: '/quarantine',
    name: 'Quarantine',
    component: () => import('@/views/Quarantine/Index'),
    meta: {
      allowNonOnboarded: false,
      fullWidth: true,
      breadcrumbTrail: [
        {
          name: 'Quarantine',
        },
      ],
      auth: {
        permission: 'read_quarantine',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('records/getQuarantinedRecords');
      store.dispatch('records/getResolvedRecords');
      next();
    },
  },
  {
    path: '/quarantine/:entity_name/:record_id',
    name: 'QuarantinedRecord',
    component: () => import('@/views/Quarantine/Record'),
    props: true,
    meta: {
      allowNonOnboarded: false,
      fullWidth: true,
      breadcrumbTrail: [
        {
          name: 'Quarantine',
        },
        {
          name: 'QuarantinedRecord',
          title: null,
        },
      ],
      auth: {
        permission: 'read_quarantine',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const destination = to;
      store.dispatch('entities/list');
      const promises = [
        store.dispatch('records/get', {
          entity: destination.params.entity_name,
          id: destination.params.record_id,
        }),
        store.dispatch('entities/get', destination.params.entity_name),
      ];
      store.dispatch('users/getUsers');
      store.dispatch('records/list', { name: destination.params.entity_name });
      Promise.all(promises).then((responses) => {
        const record = responses[0];
        destination.meta.breadcrumbTrail[1].title = record.name;
        next();
      });
    },
  },
];

export default quarantineRoutes;
