import Vue from 'vue';
import Vuex from 'vuex';
// Modules
import auth from '@/store/modules/auth';
import progress from '@/store/modules/progress';
import entities from '@/store/modules/entities';
import sync from '@/store/modules/sync';
import activationsync from '@/store/modules/activationsync';
import activationssyncerrors from '@/store/modules/activationssyncerrors';
import syncerrors from '@/store/modules/syncerrors';
import records from '@/store/modules/records';
import user from '@/store/modules/user';
import application from '@/store/modules/application';
import mainmenu from '@/store/modules/mainmenu';
import users from '@/store/modules/users';
import datasets from '@/store/modules/datasets';
import referencetables from '@/store/modules/referencetables';
import supertables from '@/store/modules/supertables';
import duplicates from '@/store/modules/duplicates';
import mappingtables from '@/store/modules/mappingtables';
import datasources from '@/store/modules/datasources';
import gluedatabases from '@/store/modules/gluedatabases';
import redshiftschemas from '@/store/modules/redshiftschemas';
import starburst from '@/store/modules/starburst';
import pipelines from '@/store/modules/pipelines';
import mediaocean from '@/store/modules/mediaocean';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    progress,
    entities,
    user,
    application,
    mainmenu,
    records,
    users,
    datasets,
    referencetables,
    supertables,
    duplicates,
    mappingtables,
    datasources,
    gluedatabases,
    redshiftschemas,
    pipelines,
    sync,
    syncerrors,
    activationsync,
    activationssyncerrors,
    mediaocean,
    starburst,
  },
});

export default store;
