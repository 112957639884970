<template>
  <app-layout
    app_name="MDM"
    :breadcrumb_trail="$root.breadcrumbTrail"
    :user_access_token="user_access_token"
    :user_email="$store.getters.emailAddress"
    :first_name="$store.getters.firstName"
    :last_name="$store.getters.lastName"
    :show_profile="profile !== null"
    :show_app_grid="showAppGrid"
    :menu_items="permittedMenuItems"
    :show_footer="!$route.meta.hideFooter"
    :container_class="containerClass"
  >
    <MessageAlerts />
    <router-view/>
    <template v-slot:after-footer>
      <loading-spinner
        message="Give us a minute while we load the freshest data."
        v-if="loadingModules.length > 0"
      />
      <a-modal
        ref="confirmModal"
        :ok-title="confirmModal.okText"
        :cancel-title="confirmModal.cancelText"
        @ok="confirmModal.okFunction"
        @cancel="confirmModal.cancelFunction"
        cancel-variant="light"
        v-model="confirmModalVisible"
      >
        <p class="my-2">{{confirmModal.text}}</p>
      </a-modal>
    </template>
  </app-layout>
</template>
<style>
:root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 5px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>
<style lang="scss" scoped>
:deep(#profile-help-icon) {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  img {
    max-width: 100%;
    height: 18px;
  }
  &:hover, &:focus, &:active {
    outline: none;
  }
}
#site-footer {
  padding: 8px 25px;
  img {
    height: 24px;
  }
}
:deep(#mainMenu) img {
  max-width: 25px;
  height: 25px;
}
</style>
<style lang="scss">
.popover-body .record-highlights {
  mark {
    background-color: #1398ce;
    color: #fff;
  }
  label {
    margin-bottom: 0;
  }
  > li {
    border-right: none;
  }
}
.b-table-sticky-header > .table.b-table > thead > tr > th.position-relative {
  position: sticky !important;
}
</style>
<script>
import { mapGetters, mapState } from 'vuex';
import MessageAlerts from '@/components/MessageAlerts';
import menuItems from '@/menuitems';
import permissions from '@/mixins/permissions';
import confirm from '@/mixins/confirm';
import alphabetizeByKey from '@/utilities/alphabetizeByKey';
import router from './router';

export default {
  name: 'MasterDataManagement',
  router,
  components: {
    MessageAlerts,
  },
  mixins: [
    permissions,
    confirm,
  ],
  computed: {
    containerClass() {
      if (this.$route.meta && this.$route.meta.fullWidth === true) {
        return 'container-fluid';
      }
      return 'container';
    },
    ...mapGetters({
      profile: 'profile',
      permissions: 'user/permissions',
      permittedMenuItems: 'mainmenu/permittedMenuItems',
      email: 'emailAddress',
      isAuthenticated: 'isAuthenticated',
    }),
    ...mapState({
      user_access_token: state => state.auth.accessToken,
      refreshToken: state => state.auth.refreshToken,
      loadingModules: state => state.progress.loadingModules,
      user: state => state.user.user,
    }),
    showAppGrid() {
      return process.env.VUE_APP_SHOW_APP_GRID === 'true';
    },
    pendoAccountId() {
      const vm = this;
      return vm.$route.path.indexOf('apps/videotracker') > -1 ? 'IVT' : 'PCT';
    },
    pendoData() {
      const vm = this;
      return {
        visitor: {
          id: vm.email,
        },
        account: {
          id: 'MDM',
        },
      };
    },
  },
  data() {
    return {
      confirmModalVisible: false,
      pendoInitialized: false,
      confirmModal: {
        text: '',
        okText: '',
        cancelText: '',
        okFunction: () => {},
        cancelFunction: () => {},
      },
      breadcrumbTrail: [],
    };
  },
  methods: {
    setUser(user) {
      const vm = this;
      vm.$store.commit('user/setUser', user);
    },
    initializePendo() {
      const vm = this;
      // Call this whenever information about your visitors becomes available
      // Please use Strings, Numbers, or Bools for value types.
      // eslint-disable-next-line no-undef
      pendo.initialize(vm.pendoData);
      vm.pendoInitialized = true;
    },
    alphabetizeByKey(key, a, b) {
      return alphabetizeByKey(key, a, b);
    },
    slugify(text, separator) {
      let textSeparator = '-';
      if (separator) {
        textSeparator = separator;
      }
      return text.toString().toLowerCase()
        .replace(/\s+/g, textSeparator)
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, textSeparator)
        .replace(/^-+/, '')
        .replace(/-+$/, '');
    },
  },
  created() {
    const vm = this;
    vm.$router.afterEach((to) => {
      vm.$root.breadcrumbTrail = to.meta.breadcrumbTrail;
      if (to.meta && to.meta.menuItems) {
        vm.$store.commit('mainmenu/setMenuItems', to.meta.menuItems);
      } else {
        vm.$store.commit('mainmenu/setMenuItems', menuItems);
      }
    });
    if (vm.email) {
      vm.initializePendo();
    }
    vm.$root.breadcrumbTrail = vm.$route.meta.breadcrumbTrail;
  },
  watch: {
    email: {
      handler: function updatedEmail() {
        this.initializePendo();
      },
    },
    breadcrumbTrail: {
      deep: true,
      handler: function breadcrumbTrailHandler(trail) {
        if (trail) {
          const reversed = [{ title: 'MDM' }, ...trail].reverse();
          const title = reversed.map((crumb) => {
            if (crumb.title) {
              return crumb.title;
            }
            return crumb.name;
          }).join(' - ');
          document.title = title;
        }
      },
    },
  },
};
</script>
