/* eslint-disable object-curly-newline */
export default {
  namespaced: true,
  state: {
    errorMessage: null,
    successMessage: null,
    loadingModules: [],
  },
  getters: {},
  actions: {
    setErrorMessage({ commit }, message) {
      commit('setError', message);
      /* setTimeout(() => {
        commit('setError', null);
      }, 5000); */
    },

    setSuccessMessage({ commit }, message) {
      commit('setSuccess', message);
      /* setTimeout(() => {
        commit('setSuccess', null);
      }, 5000); */
    },

    setProcessing({ commit }, moduleName) {
      commit('setProcessing', moduleName);
    },
  },
  mutations: {
    setError(state, message) {
      // eslint-disable-next-line no-param-reassign
      state.errorMessage = message;
    },

    setSuccess(state, message) {
      // eslint-disable-next-line no-param-reassign
      state.successMessage = message;
    },

    setProcessing(state, id) {
      if (state.loadingModules.indexOf(id) === -1) {
        // eslint-disable-next-line no-param-reassign
        state.loadingModules.push(id);
      }
    },

    removeProcessing(state, id) {
      state.loadingModules.splice(state.loadingModules.indexOf(id), 1);
    },
  },
};
