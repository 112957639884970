/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import alphabetizeByKey from '@/utilities/alphabetizeByKey';

export default {
  namespaced: true,
  state: {
    all: [],
    currentTable: {
      name: null,
      entity: null,
      keyField: null,
      referenceFields: [],
    },
  },
  getters: {},
  actions: {

    list({ commit, dispatch }, showSpinner = true) {
      const vm = this;
      const url = 'supertables?fields=id,name,status,entity,error,createdAt,createdBy,updatedAt,dataRefreshedAt,updatedBy';
      const responseTables = [];
      if (showSpinner) {
        commit('progress/setProcessing', url, { root: true });
      }
      const getData = (pageUrl) => {
        vm.$http.get(pageUrl).then((response) => {
          responseTables.push(...response.data.items);
          if (response.data.next) {
            return getData(response.data.next);
          }
          commit('progress/removeProcessing', url, { root: true });
          commit('setTables', responseTables);
          return responseTables;
        }, (error) => {
          dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
          commit('progress/removeProcessing', url, { root: true });
          return error.response.data.error.message;
        });
      };
      return getData(url);
    },

    create({ dispatch, commit }, data) {
      const vm = this;
      const url = 'supertables';
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url, data).then((response) => {
        dispatch('progress/setSuccessMessage', 'Successfully created reference table', { root: true });
        commit('progress/removeProcessing', url, { root: true });
        dispatch('progress/setSuccessMessage', 'Successfully created table', { root: true });
        return response.data;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    read({ commit, dispatch }, id) {
      const vm = this;
      const url = `supertables/${id}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((response) => {
        const table = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentTable', table);
        return table;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    update({ commit, dispatch }, data) {
      const vm = this;
      const url = `supertables/${data.id}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.put(url, data).then((response) => {
        const table = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentTable', table);
        dispatch('progress/setSuccessMessage', 'Successfully updated table', { root: true });
        return table;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

  },
  mutations: {
    setTables(state, tables) {
      state.all = tables.sort(alphabetizeByKey.bind(null, { key: 'name', desc: false }));
    },
    setCurrentTable(state, table) {
      state.currentTable = table;
    },
  },
};
