/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import alphabetizeByKey from '@/utilities/alphabetizeByKey';

const defaultSchedule = {
  id: null,
  cron: '0 0 * ? 1',
};
export default {
  namespaced: true,
  state: {
    all: [],
    currentTable: {
      name: null,
      entity: null,
      keyField: null,
      referenceFields: [],
      dataset: null,
    },
    currentSchedule: defaultSchedule,
  },
  getters: {},
  actions: {

    list({ commit, dispatch }, dataset) {
      const vm = this;
      const url = `datasets/${dataset}/referencetables?fields=id,name,status,error,createdAt,createdBy,updatedAt,dataRefreshedAt,updatedBy`;
      const responseTables = [];
      commit('progress/setProcessing', url, { root: true });
      const getData = (pageUrl) => {
        vm.$http.get(pageUrl).then((response) => {
          responseTables.push(...response.data.items);
          if (response.data.next) {
            return getData(response.data.next);
          }
          commit('progress/removeProcessing', url, { root: true });
          commit('setTables', responseTables);
          return responseTables;
        }, (error) => {
          dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
          commit('progress/removeProcessing', url, { root: true });
          return error.response.data.error.message;
        });
      };
      return getData(url);
    },

    listAllReferenceTables({ commit, dispatch }, showSpinner = true) {
      const vm = this;
      const url = 'referencetables?fields=id,name,status,entity,dataset,error,createdAt,createdBy,updatedAt,dataRefreshedAt,updatedBy';
      const responseTables = [];
      if (showSpinner) {
        commit('progress/setProcessing', url, { root: true });
      }
      const getData = (pageUrl) => {
        vm.$http.get(pageUrl).then((response) => {
          responseTables.push(...response.data.items);
          if (response.data.next) {
            return getData(response.data.next);
          }
          commit('progress/removeProcessing', url, { root: true });
          commit('setTables', responseTables);
          return responseTables;
        }, (error) => {
          dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
          commit('progress/removeProcessing', url, { root: true });
          return error.response.data.error.message;
        });
      };
      return getData(url);
    },

    listForEntity({ commit, dispatch }, entity) {
      const vm = this;
      const url = `entities/${entity}/reference?fields=id,name,referenceFields,keyField,isSuperTable`;
      const responseTables = [];
      commit('progress/setProcessing', url, { root: true });
      const getData = (pageUrl) => {
        vm.$http.get(pageUrl).then((response) => {
          responseTables.push(...response.data.items);
          if (response.data.next) {
            return getData(response.data.next);
          }
          commit('progress/removeProcessing', url, { root: true });
          commit('setTables', responseTables);
          return responseTables;
        }, (error) => {
          dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
          commit('progress/removeProcessing', url, { root: true });
          return error.response.data.error.message;
        });
      };
      return getData(url);
    },

    create({ dispatch, commit }, data) {
      const vm = this;
      const url = 'referencetables';
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url, data).then((response) => {
        dispatch('progress/setSuccessMessage', 'Successfully created reference table', { root: true });
        dispatch('list', data.dataset);
        commit('progress/removeProcessing', url, { root: true });
        dispatch('progress/setSuccessMessage', 'Successfully created table', { root: true });
        return response.data;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    read({ commit, dispatch }, id) {
      const vm = this;
      const url = `referencetables/${id}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((response) => {
        const table = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentTable', table);
        return table;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    readSchedule({ commit, dispatch }, id) {
      const vm = this;
      const url = `referencetables/${id}/schedule`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((response) => {
        const table = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentSchedule', table);
        return table;
      }, (error) => {
        if (error.response.status === 404) {
          const schedule = {
            ...defaultSchedule,
            id,
          };
          commit('setCurrentSchedule', schedule);
          commit('progress/removeProcessing', url, { root: true });
          return schedule;
        }
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    updateCurrentTable({ state, commit, dispatch }) {
      const vm = this;
      const url = `referencetables/${state.currentTable.id}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.put(url, state.currentTable).then((response) => {
        const refTable = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentTable', refTable);
        dispatch('progress/setSuccessMessage', 'Successfully updated table', { root: true });
        return refTable;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    updateCurrentSchedule({ state, commit, dispatch }) {
      const vm = this;
      const url = `referencetables/${state.currentSchedule.id}/schedule`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.put(url, state.currentSchedule).then((response) => {
        const schedule = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentSchedule', schedule);
        dispatch('progress/setSuccessMessage', 'Successfully updated schedule', { root: true });
        return schedule;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    reprocessTable({ commit, dispatch }, id) {
      const vm = this;
      const url = `referencetables/${id}/reprocess`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url).then((response) => {
        const table = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentTable', table);
        return table;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

    deleteCurrentTable({ state, commit, dispatch }) {
      const vm = this;
      const url = `referencetables/${state.currentTable.id}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.delete(url, state.currentTable).then((response) => {
        const dataset = response.data;
        dispatch('list', state.currentTable.dataset);
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentTable', {
          name: null,
          entity: null,
          keyField: null,
          referenceFields: [],
          dataset: null,
        });
        return dataset;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

  },
  mutations: {
    setTables(state, tables) {
      state.all = tables.sort(alphabetizeByKey.bind(null, { key: 'name', desc: false }));
    },
    setCurrentTable(state, table) {
      state.currentTable = table;
    },
    setCurrentSchedule(state, schedule) {
      state.currentSchedule = schedule;
    },
  },
};
