import store from '@/store/index';

const entitiesRoutes = [
  {
    path: '/entities',
    name: 'Entities',
    component: () => import('@/views/Entities/Index'),
    meta: {
      allowNonOnboarded: false,
      breadcrumbTrail: [],
      auth: {
        permission: 'read_entity',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('entities/list').then(() => {
        next();
      });
    },
  },
  {
    path: '/entities/create',
    name: 'CreateEntity',
    component: () => import('@/views/Entities/Edit'),
    meta: {
      allowNonOnboarded: false,
      auth: {
        permission: 'create_entity',
        redirect: 'Entities',
      },
      breadcrumbTrail: [
        {
          name: 'Entities',
          title: null,
        },
        {
          name: 'CreateEntity',
          title: 'Create',
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('users/getUsers');
      next();
    },
  },
  {
    path: '/entities/:entity',
    name: 'Entity',
    component: () => import('@/views/Entities/Entity'),
    props: true,
    meta: {
      allowNonOnboarded: false,
      auth: {
        permission: 'read_record',
        redirect: 'Entities',
      },
      breadcrumbTrail: [
        {
          name: 'Entity',
          title: null,
        },
      ],
      fullWidth: true,
    },
    beforeEnter: (to, from, next) => {
      store.commit('records/setFilter', null);
      store.dispatch('sync/get', to.params.entity);
      const destination = to;
      destination.meta.breadcrumbTrail[0].title = to.params.entity;
      next();
    },
  },
  {
    path: '/entities/:entity/upload',
    name: 'BulkUpload',
    component: () => import('@/views/Entities/Upload'),
    props: true,
    meta: {
      allowNonOnboarded: false,
      auth: {
        permission: 'create_record',
        redirect: 'Entities',
      },
      breadcrumbTrail: [
        {
          name: 'Entity',
          title: null,
        },
        {
          name: 'BulkUpload',
          title: 'Bulk Upload',
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('records/list', { name: 'Data Groups' });
      const destination = to;
      destination.meta.breadcrumbTrail[0].title = to.params.entity;
      if (
        !store.state.entities.ActiveEntity
        || !store.state.entities.ActiveEntity.name
        || store.state.entities.ActiveEntity.name.toLowerCase() !== to.params.entity.toLowerCase()
      ) {
        store.dispatch('entities/get', to.params.entity);
      }
      next();
    },
  },
  {
    path: '/entities/:entity/upload/:uploadId',
    name: 'BulkUploadResults',
    component: () => import('@/views/Entities/UploadResult'),
    props: true,
    meta: {
      allowNonOnboarded: false,
      auth: {
        permission: 'create_record',
        redirect: 'Entities',
      },
      breadcrumbTrail: [
        {
          name: 'Entity',
          title: null,
        },
        {
          name: 'BulkUpload',
          title: 'Bulk Upload',
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('records/list', { name: 'Data Groups' });
      const destination = to;
      destination.meta.breadcrumbTrail[0].title = to.params.entity;
      if (
        !store.state.entities.ActiveEntity
        || !store.state.entities.ActiveEntity.name
        || store.state.entities.ActiveEntity.name.toLowerCase() !== to.params.entity.toLowerCase()
      ) {
        store.dispatch('entities/get', to.params.entity);
      }
      next();
    },
  },
  {
    path: '/entities/:entity/search',
    name: 'BulkSearch',
    component: () => import('@/views/Entities/BulkSearch'),
    props: true,
    meta: {
      allowNonOnboarded: false,
      auth: {
        permission: 'read_record',
        redirect: 'Entities',
      },
      breadcrumbTrail: [
        {
          name: 'Entity',
          title: null,
        },
        {
          name: 'BulkSearch',
          title: 'Bulk Search',
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      const destination = to;
      store.dispatch('records/list', { name: 'Data Groups' });
      destination.meta.breadcrumbTrail[0].title = to.params.entity;
      if (
        !store.state.entities.ActiveEntity
        || !store.state.entities.ActiveEntity.name
        || store.state.entities.ActiveEntity.name.toLowerCase() !== to.params.entity.toLowerCase()
      ) {
        store.dispatch('entities/get', to.params.entity);
      }
      next();
    },
  },
  {
    path: '/entities/:entity/search/:id',
    name: 'BulkSearchResults',
    component: () => import('@/views/Entities/BulkSearch'),
    props: true,
    meta: {
      allowNonOnboarded: false,
      auth: {
        permission: 'read_record',
        redirect: 'Entities',
      },
      breadcrumbTrail: [
        {
          name: 'Entity',
          title: null,
        },
        {
          name: 'BulkSearch',
          title: 'Bulk Search',
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      const destination = to;
      store.dispatch('records/list', { name: 'Data Groups' });
      destination.meta.breadcrumbTrail[0].title = to.params.entity;
      if (
        !store.state.entities.ActiveEntity
        || !store.state.entities.ActiveEntity.name
        || store.state.entities.ActiveEntity.name.toLowerCase() !== to.params.entity.toLowerCase()
      ) {
        store.dispatch('entities/get', to.params.entity);
      }
      next();
    },
  },
  {
    path: '/entities/:entity/edit',
    name: 'EditEntity',
    component: () => import('@/views/Entities/Edit'),
    props: true,
    meta: {
      auth: {
        permission: 'update_entity',
        redirect: 'Entities',
      },
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'Entity',
          title: null,
        },
        {
          name: 'EditEntity',
          title: 'Edit',
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      const destination = to;
      destination.meta.breadcrumbTrail[0].title = to.params.entity;
      store.dispatch('users/getUsers');
      store.dispatch('entities/get', to.params.entity).then(() => {
        next();
      });
    },
  },
  {
    path: '/entities/:entity/create',
    name: 'CreateRecord',
    component: () => import('@/views/Entities/Record'),
    props: true,
    meta: {
      allowNonOnboarded: false,
      auth: {
        permission: 'create_record',
        redirect: 'Entities',
      },
      breadcrumbTrail: [
        {
          name: 'Entity',
          title: null,
        },
        {
          name: 'CreateRecord',
          title: 'Create',
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      store.commit('records/setFilter', null);
      const destination = to;
      destination.meta.breadcrumbTrail[0].title = to.params.entity;
      store.commit('records/resetRecord');
      const promises = [
        store.dispatch('users/getUsers'),
        store.dispatch('entities/list'),
        store.dispatch('sync/get', to.params.entity),
        store.dispatch('entities/get', to.params.entity),
      ];
      Promise.all(promises).then(() => {
        next();
      });
    },
  },
  {
    path: '/entities/:entity/:id',
    name: 'Record',
    component: () => import('@/views/Entities/Record'),
    props: true,
    meta: {
      allowNonOnboarded: false,
      auth: {
        permission: 'read_record',
        redirect: 'Entities',
      },
      breadcrumbTrail: [
        {
          name: 'Entity',
          title: null,
        },
        {
          name: 'Record',
          title: null,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      const destination = to;
      destination.meta.breadcrumbTrail[0].title = to.params.entity;
      store.dispatch('users/getUsers');
      store.dispatch('entities/list');
      store.dispatch('sync/get', to.params.entity);
      const promises = [
        store.dispatch('entities/get', to.params.entity),
        store.dispatch('records/get', { entity: to.params.entity, id: to.params.id }),
      ];
      Promise.all(promises).then((responses) => {
        store.commit('records/setFilter', null);
        destination.meta.breadcrumbTrail[1].title = responses[0].name;
        next();
      });
    },
  },
  {
    path: '/entities/:entity/:id/merge',
    name: 'MergeRecord',
    component: () => import('@/views/Entities/MergeRecord'),
    props: true,
    meta: {
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'Entity',
          title: null,
        },
        {
          name: 'Record',
          title: null,
        },
        {
          name: 'MergeRecord',
          title: 'Merge',
        },
      ],
      auth: {
        permission: 'update_record',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      store.commit('records/setFilter', null);
      const destination = to;
      store.dispatch('entities/list');
      store.dispatch('sync/get', to.params.entity);
      const promises = [
        store.dispatch('records/get', { entity: to.params.entity, id: to.params.id }),
        store.dispatch('entities/get', to.params.entity),
        store.dispatch('users/getUsers'),
      ];
      Promise.all(promises).then((responses) => {
        const record = responses[0];
        destination.meta.breadcrumbTrail[1].title = record.name;
        destination.meta.breadcrumbTrail[0].title = responses[1].data.name;
        store.dispatch('entities/get', record.entity);
        next();
      });
    },
  },
  {
    path: '/entities/:entity/:id/cleanup',
    name: 'CleanupRecord',
    component: () => import('@/views/Entities/CleanupRecord'),
    props: true,
    meta: {
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'Entity',
          title: null,
        },
        {
          name: 'Record',
          title: null,
        },
        {
          name: 'CleanupRecord',
          title: 'Clean Up',
        },
      ],
      auth: {
        permission: 'update_record',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      store.commit('records/setFilter', null);
      const destination = to;
      store.dispatch('entities/list');
      store.dispatch('sync/get', to.params.entity);
      const promises = [
        store.dispatch('records/get', { entity: to.params.entity, id: to.params.id }),
        store.dispatch('entities/get', to.params.entity),
        store.dispatch('users/getUsers'),
        store.dispatch('entities/list'),
        store.dispatch('records/getHarmonizations', { entity: to.params.entity, id: to.params.id }),
      ];
      Promise.all(promises).then((responses) => {
        const record = responses[0];
        destination.meta.breadcrumbTrail[1].title = record.name;
        destination.meta.breadcrumbTrail[0].title = responses[1].data.name;
        store.dispatch('entities/get', record.entity);
        next();
      });
    },
  },
];

export default entitiesRoutes;
