import store from '@/store/index';
import slugify from '../utilities/slugify';

const routes = [
  {
    path: '/datasources',
    name: 'DataSources',
    component: () => import('@/views/DataSources/Index'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'DataSources',
          title: 'Data Sources',
        },
      ],
      auth: {
        permission: 'read_data-source',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('datasources/list');
      store.dispatch('starburst/list');
      next();
    },
  },
  {
    path: '/datasources/create',
    name: 'CreateDataSource',
    component: () => import('@/views/DataSources/Create'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'DataSources',
          title: 'Data Sources',
        },
        {
          name: 'CreateDataSource',
          title: 'Create',
        },
      ],
      auth: {
        permission: 'create_data-source',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('gluedatabases/list', { });
      store.dispatch('redshiftschemas/list', { });
      store.dispatch('starburst/list');
      store.dispatch('pipelines/list', { });
      next();
    },
  },
  {
    path: '/datasources/:id',
    name: 'DataSource',
    component: () => import('@/views/DataSources/Read'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'DataSources',
          title: 'Data Sources',
        },
        {
          name: 'DataSource',
          title: 'Update',
        },
      ],
      auth: {
        permission: 'read_data-source',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const destination = to;
      store.dispatch('mappingtables/list', { datasource: to.params.id });
      store.dispatch('gluedatabases/list', { });
      store.dispatch('redshiftschemas/list', { });
      store.dispatch('pipelines/list', { });
      store.dispatch('starburst/list');
      store.dispatch('datasources/read', to.params.id).then((datasource) => {
        destination.meta.breadcrumbTrail[1].title = datasource.name;
        next();
      });
    },
  },
  {
    path: '/harmonization',
    name: 'Harmonization',
    component: () => import('@/views/Harmonizations/MappingTables'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'Harmonization',
          title: 'Harmonization Service',
        },
      ],
      auth: {
        permission: 'read_mapping-table',
        redirect: 'AccessDenied',
      },
    },
    beforeEnter: (to, from, next) => {
      const promises = [
        store.dispatch('mappingtables/list', {}),
        store.dispatch('datasources/list'),
        store.dispatch('entities/list'),
      ];
      Promise.all(promises).then(() => {
        next();
      });
    },
  },
  {
    path: '/harmonization/:datasource',
    name: 'DatasourceMappingTables',
    component: () => import('@/views/Harmonizations/DatasourceMappingTable'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'Harmonization',
          title: 'Harmonization Service',
        },
        {
          name: 'DatasourceMappingTables',
          title: '',
        },
      ],
      auth: {
        permission: 'read_mapping-table',
        redirect: 'Harmonization',
      },
    },
    props: true,
    beforeEnter: (to, from, next) => {
      const destination = to;
      store.dispatch('entities/list');
      store.dispatch('datasources/list').then((response) => {
        const datasource = response.find(x => x.slug === `${slugify(to.params.datasource, '_')}_mapping`);
        if (!datasource) {
          next({
            name: 'Harmonization',
          });
        } else {
          destination.meta.breadcrumbTrail[1].title = datasource.name;
          store.dispatch('mappingtables/list', {
            datasource: to.params.datasource,
          });
          next();
        }
      });
    },
  },
  {
    path: '/harmonization/:datasource/:entity',
    name: 'MappingTable',
    component: () => import('@/views/Harmonizations/MappingTable'),
    meta: {
      fullWidth: true,
      allowNonOnboarded: false,
      breadcrumbTrail: [
        {
          name: 'Harmonization',
          title: 'Harmonization Service',
        },
        {
          name: 'DatasourceMappingTables',
          title: '',
        },
        {
          name: 'MappingTable',
          title: '',
        },
      ],
      auth: {
        permission: 'read_mapping-table',
        redirect: 'Harmonization',
      },
    },
    props: true,
    beforeEnter: (to, from, next) => {
      const destination = to;
      store.dispatch('entities/list');
      store.dispatch('mappingtables/getData', {
        datasource: to.params.datasource,
        entity: to.params.entity,
        mapped: false,
      });
      store.dispatch('mappingtables/list', {
        datasource: to.params.datasource,
      });
      Promise.all([
        store.dispatch('mappingtables/get', {
          datasource: to.params.datasource,
          entity: to.params.entity,
        }),
        store.dispatch('datasources/list'),
      ]).then((responses) => {
        if (responses[0].status === 404) {
          next({
            name: 'DatasourceMappingTables',
            params: {
              datasource: to.params.datasource,
            },
          });
        } else {
          const datasource = responses[1].find(x => x.slug === `${slugify(to.params.datasource, '_')}_mapping`);
          destination.meta.breadcrumbTrail[1].title = datasource.name;
          destination.meta.breadcrumbTrail[2].title = to.params.entity;
          next();
        }
      });
    },
  },
];

export default routes;
