export default {
  id: null,
  datasource: null,
  firstEntity: {
    entity: null,
    field: null,
  },
  secondEntity: {
    entity: null,
    field: null,
  },
};
