const alphabetizeByKey = (key, a, b) => {
  let aVal;
  let bVal;
  if (typeof key === 'string') {
    aVal = a[key];
    bVal = b[key];
    if (typeof aVal === 'string') {
      aVal = aVal.trim().toLowerCase();
    }
    if (typeof bVal === 'string') {
      bVal = bVal.trim().toLowerCase();
    }
    if (aVal < bVal) return -1;
    if (aVal > bVal) return 1;
  } else if (key.desc) {
    try {
      aVal = a[key.key].toLowerCase();
    } catch {
      aVal = a[key.key];
    }
    try {
      bVal = b[key.key].toLowerCase();
    } catch {
      bVal = b[key.key];
    }
    if (aVal > bVal) return -1;
    if (aVal < bVal) return 1;
  } else {
    try {
      aVal = a[key.key].toLowerCase();
    } catch {
      aVal = a[key.key];
    }
    try {
      bVal = b[key.key].toLowerCase();
    } catch {
      bVal = b[key.key];
    }
    if (aVal < bVal) return -1;
    if (aVal > bVal) return 1;
  }
  return 0;
};

export default alphabetizeByKey;
