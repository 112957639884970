/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import alphabetizeByKey from '@/utilities/alphabetizeByKey';

export default {
  namespaced: true,
  state: {
    all: [],
    refreshedAt: null,
    fetching: false,
  },
  mutations: {
    setUsers: (state, users) => {
      state.all.push(...users);
      state.refreshedAt = new Date();
    },
    resetUsers: (state) => {
      state.all = [];
      state.refreshedAt = null;
    },
    setFetching: (state, fetching) => {
      state.fetching = fetching;
    },
  },
  actions: {
    getUsers({ state, dispatch, commit }) {
      if (!state.fetching) {
        commit('setFetching', true);
        const refreshedMoment = dayjs(state.refreshedAt);
        if (
          !state.refreshedAt
          || refreshedMoment.add(10, 'minutes').isBefore(dayjs())
          || !state.all.length
        ) {
          return dispatch('getUsersFromApi').then(users => users);
        }
      }
      return new Promise(resolve => resolve(state.all));
    },
    // eslint-disable-next-line no-unused-vars
    getUsersFromApi({ rootState, commit }) {
      const vm = this;
      const url = `${process.env.VUE_APP_PROTEGO_API_ROOT}/users`;
      commit('resetUsers');
      const getData = () => vm.$http.get(url).then((response) => {
        commit('setUsers', response.data.sort(alphabetizeByKey.bind(null, 'email')));
        commit('setFetching', false);
        return response.data.sort(alphabetizeByKey.bind(null, 'email'));
      }, () => new Error('Error retrieving user'));
      return getData();
    },
  },
};
