/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */

export default {
  namespaced: true,
  state: {
    pipelinesLoaded: false,
    pipelines: [],
  },
  getters: {},
  actions: {
    list({ commit }, { offset }) {
      const vm = this;
      const url = 'pipelines';
      commit('setPipelinesLoaded', false);
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        if (!offset) {
          commit('setPipelines', data.data.items);
        } else {
          commit('appendPipelines', data.data.items);
        }
        commit('setPipelinesLoaded', true);
        return data;
      }, error => error);
    },
  },
  mutations: {
    setPipelines(state, payload) {
      state.pipelines = payload;
    },
    setPipelinesLoaded(state, payload) {
      state.pipelinesLoaded = payload;
    },
  },
};
