/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */

export default {
  namespaced: true,
  state: {
    databasesLoaded: false,
    tablesLoaded: false,
    tables: [],
    databases: [],
    currentDatabase: null,
  },
  getters: {},
  actions: {
    list({ commit }, { afterId }) {
      const vm = this;
      let url = 'gluedatabases';
      if (afterId) {
        url = `${url}?after_id=${afterId}`;
      } else {
        commit('setDatabasesLoaded', false);
      }
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        if (!afterId) {
          commit('setDatabases', data.data.items);
        } else {
          commit('appendDatabases', data.data.items);
        }
        commit('setDatabasesLoaded', true);
        return data;
      }, error => error);
    },
    getTables({ commit, state }, { database, afterId }) {
      const vm = this;
      commit('setCurrentDatabase', database);
      let url = `gluedatabases/${database}/tables`;
      if (afterId) {
        url = `${url}?after_id=${afterId}`;
      } else {
        commit('setTablesLoaded', false);
        commit('setTables', []);
      }
      const loadData = (pageUrl, firstPage) => vm.$http.get(pageUrl).then((data) => {
        if (database === state.currentDatabase) {
          if (firstPage) {
            commit('setTables', data.data.items);
          } else {
            commit('appendTables', data.data.items);
          }
          commit('setTablesLoaded', true);
          if (data.data.next) {
            loadData(data.data.next, false);
          }
        }
        return data;
      }, error => error);
      return loadData(url);
    },
  },
  mutations: {
    setCurrentDatabase(state, payload) {
      state.currentDatabase = payload;
    },
    setDatabases(state, payload) {
      state.databases = payload;
    },
    setDatabasesLoaded(state, payload) {
      state.databasesLoaded = payload;
    },
    appendDatabases(state, payload) {
      state.databases.push(...payload);
    },
    setTables(state, payload) {
      state.tables = payload;
    },
    setTablesLoaded(state, payload) {
      state.tablesLoaded = payload;
    },
    appendTables(state, payload) {
      state.tables.push(...payload);
    },
  },
};
