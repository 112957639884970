export default (str, replacement) => {
  let theString = str;
  const replacementValue = replacement || '-';
  theString = theString.replace(/^\s+|\s+$/g, ''); // trim
  theString = theString.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç';
  const to = 'aaaaeeeeiiiioooouuuunc';
  for (let i = 0, l = from.length; i < l; i += 1) {
    theString = theString.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  theString = theString.replace(/[^a-z0-9 -]/g, replacementValue) // remove invalid chars
    .replace(/\s+/g, replacementValue) // collapse whitespace and replace by -
    .replace(/-+/g, replacementValue); // collapse dashes

  return theString;
};
