/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */

export default {
  namespaced: true,
  state: {
    errorsLoaded: false,
    errors: [],
    errorNextUrl: null,
  },
  getters: {},
  actions: {
    list({ commit }, id) {
      const vm = this;
      const url = `activationsync/${id}/errors`;
      commit('setErrorsLoaded', false);
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        commit('setErrors', data.data.items);
        commit('setErrorNextUrl', data.data.next);
        commit('setErrorsLoaded', true);
        if (data.data.next) {
          // dispatch('listMore');
        }
        return data;
      }, error => error);
    },
    listMore({ commit, state }) {
      const vm = this;
      if (state.errorNextUrl) {
        commit('progress/setProcessing', state.errorNextUrl, { root: true });
        return vm.$http.get(state.errorNextUrl).then((data) => {
          commit('progress/removeProcessing', state.errorNextUrl, { root: true });
          commit('setErrorNextUrl', data.data.next);
          commit('appendErrors', data.data.items);
          commit('setErrorsLoaded', true);
          if (data.data.next) {
            // dispatch('listMore');
          }
          return data;
        }, error => error);
      }
      return null;
    },
  },
  mutations: {
    setErrorNextUrl(state, payload) {
      state.errorNextUrl = payload;
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
    setErrorsLoaded(state, payload) {
      state.errorsLoaded = payload;
    },
    appendErrors(state, payload) {
      state.errors.push(...payload);
    },
  },
};
