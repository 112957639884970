/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-empty-pattern */
import dayjs from 'dayjs';

export default {
  namespaced: true,
  state: {
    user: null,
    refreshedAt: null,
  },
  getters: {
    permissions: (state) => {
      if (!state.user || !state.user.applications || !state.user.applications.find(x => x.name === 'MDM')) {
        return [];
      }
      return state.user.applications.find(x => x.name === 'MDM').permissions;
    },
    roles: (state) => {
      if (!state.user || !state.user.applications || !state.user.applications.find(x => x.name === 'MDM')) {
        return [];
      }
      return state.user.applications.find(x => x.name === 'MDM').roles;
    },
  },
  actions: {
    getFromApi({ commit }, id) {
      const url = `${process.env.VUE_APP_PROTEGO_API_ROOT}/users/${id}?environment=${process.env.VUE_APP_ENVIRONMENT}`;
      commit('progress/setProcessing', url, { root: true });
      return this.$http.get(url).then((response) => {
        commit('progress/removeProcessing', url, { root: true });
        return response.data;
      });
    },
    // eslint-disable-next-line no-unused-vars
    get({ state, dispatch, commit, rootState }, email) {
      const refreshedMoment = dayjs(state.refreshedAt);
      if (
        !state.user
        || !state.refreshedAt
        || refreshedMoment.add(10, 'minutes').isBefore(dayjs())
      ) {
        return dispatch('getFromApi', email).then((user) => {
          const loadedUser = user;
          commit('setUser', loadedUser);
          return loadedUser;
        });
      }
      return new Promise(resolve => resolve(state.user));
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      state.refreshedAt = new Date();
    },
  },
};
