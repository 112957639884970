/* eslint-disable no-param-reassign */

// Our common instance of Axios for all calls to the MDM API
// Performs three basic functions:
//  - Sets common base URL for all requests depending on the environment
//  - Sets the Authorization header for all requests
//  - Refreshes the ID token if a 401 is returned


import axios from 'axios';
import store from '@/store';
import auth from '@/services/auth';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
});

// Keep track of if the token is in the process of being refreshed.
// If more than one requests receives a 401, we don't want to try to refresh it multiple times
let isAlreadyFetchingAccessToken = false;

// Array where we will keep track of all requests which need to be re-run after token refreshes
let subscribers = [];

// Array which tracks URLs of all requests which will be re-sent
// We track this so that requests only get re-run once, otherwise we could end up stuck in a loop
// if the token is valid, but the user doesn't have rights to perform a request
const refetchAttempts = [];

// Function which is actually run after token is refreshed
const onAccessTokenFetched = (idToken) => {
  subscribers = subscribers.filter(callback => callback(idToken));
};

// Function for adding another request to the subscriber list to re-run after token refresh
const addSubscriber = (callback) => {
  subscribers.push(callback);
};


// Intercept requests and perform some common actions before calling
http.interceptors.request.use((config) => {
  // Add the ID Token as the Authorization header
  config.headers.common.Authorization = store.getters.accessToken;
  return config;
}, error => Promise.reject(error));

// Intercept responses and perform some common actions before returning
http.interceptors.response.use(response => response, (error) => {
  // const { config, response: { status } } = error;
  const originalRequest = error.config;
  let status = null;
  if (error.response && error.response.status) {
    status = error.response.status;
  } else if (error.status) {
    status = error.status;
  }
  // If we receive a 401 Unauthorized response, we want to refresh our ID Token and try it again
  if ((!status || status === 401) && refetchAttempts.indexOf(originalRequest.url) === -1) {
    // Track that we're attempting to re-run request so we don't do it more than once
    refetchAttempts.push(originalRequest.url);

    // Refresh the ID Token, but only if we're not already doing it
    if (!isAlreadyFetchingAccessToken) {
      // Track that yes, we are refreshing the token
      isAlreadyFetchingAccessToken = true;
      store.dispatch('refreshToken').then((tokenResponse) => {
        if (tokenResponse) {
          // Mark that we're no longer refreshing the token
          isAlreadyFetchingAccessToken = false;
          // Re-run the requests now that the token is done
          onAccessTokenFetched(tokenResponse.access_token);
        } else {
          auth.authenticate('ONEWP-OKTA');
        }
      });
    }

    // Function which adds request to the list of requests to re-run
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        originalRequest.headers.Authorization = accessToken;
        resolve(http(originalRequest));
      });
    });
    return retryOriginalRequest;
  }

  return Promise.reject(error);
});

export default http;
