/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import jwt from 'jwt-decode';
import dayjs from 'dayjs';
import capitalize from '@/utilities/capitalize';

const options = {
  endpoint: 'auth',
  data: {},
};

const defaultState = {
  profile: null,
  redirectUri: null,
  authorizationCode: null,
  authorizationState: null,
  accessToken: null,
  idToken: null,
  verification: null,
  requestedRoute: null,
  expires: null,
  user: null,
  refreshToken: null,
  refreshedAt: null,
  impersonatingUser: null,
};

let localState = JSON.parse(window.localStorage.getItem('mdm-auth'));
if (
  !localState
  || (
    localState
    && localState.profile
    && localState.profile.identities
    && localState.profile.identities.length
    && localState.profile.identities[0].providerName !== 'ONEWP-OKTA'
  )
) {
  window.localStorage.setItem('mdm-auth', JSON.stringify(defaultState));
  localState = JSON.parse(window.localStorage.getItem('mdm-auth'));
}
export default {
  state: localState,

  getters: {
    profile: state => state.profile,
    verification: state => state.verification,
    accessToken: state => state.accessToken,
    refreshToken: state => state.refreshToken,
    idToken: state => state.idToken,
    isAuthenticated: state => !!state.profile,
    isOnboardedUser: (state, rootGetters) => {
      // return false;
      if (
        (!!state.user && state.user.length > 0)
        || (rootGetters['user/permissions'].length)
      ) {
        return true;
      }
      return false;
    },
    expires: state => state.expires,
    requestedRoute: state => state.requestedRoute,
    emailAddress: (state) => {
      if (state.impersonatingUser) {
        return state.impersonatingUser;
      }
      if (state.profile && state.profile.identities) {
        return state.profile.identities[0].userId;
      }
      return null;
    },
    firstName: (state, rootGetters) => {
      if (state.profile && state.profile.given_name) {
        return state.profile.given_name;
      } if (rootGetters.emailAddress) {
        return capitalize(rootGetters.emailAddress.split('@')[0].split('.')[0]) || null;
      }
      return null;
    },
    lastName: (state, rootGetters) => {
      if (state.profile && state.profile.family_name) {
        return state.profile.family_name;
      } if (rootGetters.emailAddress) {
        return capitalize(rootGetters.emailAddress.split('@')[0].split('.')[1]) || null;
      }
      return null;
    },
  },

  mutations: {
    setImpersonatingUser: (state, impersonatingUser) => {
      state.impersonatingUser = impersonatingUser;
      window.localStorage.setItem('mdm-auth', JSON.stringify(state));
    },
    setauthorizationCode: (state, authorizationCode) => {
      state.authorizationCode = authorizationCode;
      window.localStorage.setItem('mdm-auth', JSON.stringify(state));
    },
    setRefreshToken: (state, refreshToken) => {
      state.refreshToken = refreshToken;
      window.localStorage.setItem('mdm-auth', JSON.stringify(state));
    },
    setauthorizationState: (state, authorizationState) => {
      state.authorizationState = authorizationState;
      window.localStorage.setItem('mdm-auth', JSON.stringify(state));
    },
    setredirectUri: (state, redirectUri) => {
      state.redirectUri = redirectUri;
      window.localStorage.setItem('mdm-auth', JSON.stringify(state));
    },
    setAccessToken: (state, accessToken) => {
      state.accessToken = accessToken;
      window.localStorage.setItem('mdm-auth', JSON.stringify(state));
    },
    setIdToken: (state, idToken) => {
      state.idToken = idToken;
      window.localStorage.setItem('mdm-auth', JSON.stringify(state));
    },
    setVerification: (state, verification) => {
      state.verification = verification;
      window.localStorage.setItem('mdm-auth', JSON.stringify(state));
    },
    setRequestedRoute: (state, route) => {
      state.requestedRoute = route;
      window.localStorage.setItem('mdm-auth', JSON.stringify(state));
    },
    setExpires: (state, expires) => {
      state.expires = expires;
      window.localStorage.setItem('mdm-auth', JSON.stringify(state));
    },
    setUser: (state, user) => {
      state.user = user;
      if (user === null) {
        state.refreshedAt = null;
      } else {
        state.refreshedAt = new Date();
      }
      window.localStorage.setItem('mdm-auth', JSON.stringify(state));
    },
    setProfile: (state, profile) => {
      state.profile = profile;
      window.localStorage.setItem('mdm-auth', JSON.stringify(state));
    },
  },

  actions: {
    signout({ commit }) {
      commit('setProfile', null);
      commit('setAccessToken', null);
      commit('setExpires', null);
    },

    setToken({ commit, dispatch }, payload) {
      const profile = jwt(payload.id_token);
      commit('setIdToken', payload.id_token);
      commit('setAccessToken', payload.access_token);
      commit('setRefreshToken', payload.refresh_token);
      const expires = dayjs().add(53, 'minutes').valueOf();
      commit('setExpires', expires);
      dispatch('setProfile', profile);
    },

    setNewToken({ commit, dispatch }, payload) {
      const profile = jwt(payload.id_token);
      commit('setIdToken', payload.id_token);
      commit('setAccessToken', payload.access_token);
      const expires = dayjs().add(53, 'minutes').valueOf();
      commit('setExpires', expires);
      dispatch('setProfile', profile);
    },

    authenticate({ commit, dispatch }, payload) {
      return new Promise((resolve) => {
        commit('setauthorizationCode', payload.authorizationCode);
        commit('setauthorizationState', payload.state);
        dispatch('getToken').then((onboarded) => {
          resolve(onboarded);
        });
      });
    },

    refreshToken({ state, commit, dispatch }) {
      const vm = this;
      options.endpoint = 'auth/refresh';
      options.data.refresh_token = state.refreshToken;
      options.data.redirect_uri = state.redirectUri;
      const url = 'tokens';
      return vm.$http.post(url, options.data).then((response) => {
        if (!response.data || response.data.exception) {
          commit('setIdToken', null);
          commit('setAccessToken', null);
          commit('setRefreshToken', null);
          const expires = dayjs().add(53, 'minutes').valueOf();
          commit('setExpires', expires);
          return null;
        }
        dispatch('setNewToken', response.data);
        return response.data;
      }, () => {
        commit('setIdToken', null);
        commit('setAccessToken', null);
        commit('setRefreshToken', null);
        const expires = dayjs().add(53, 'minutes').valueOf();
        commit('setExpires', expires);
        return null;
      });
    },

    getToken({ state, dispatch }) {
      const vm = this;
      options.data.authorization_code = state.authorizationCode;
      options.data.redirect_uri = state.redirectUri;
      const url = 'tokens';
      return vm.$http.post(url, options.data).then((response) => {
        dispatch('setToken', response.data);
      });
    },

    setProfile({ commit, dispatch, getters }, profile) {
      commit('setProfile', profile);
      return dispatch('getUser').then(() => getters.isOnboardedUser, () => getters.isOnboardedUser);
    },

    getUser({ state, getters, dispatch, commit }) {
      const refreshedMoment = dayjs(state.refreshedAt);
      const email = getters.emailAddress.toLowerCase();
      if (
        !state.refreshedAt
        || refreshedMoment.add(10, 'minutes').isBefore(dayjs())
        || !state.user[0]
        || state.user[0].email.toLowerCase() !== email
      ) {
        return dispatch('user/get', email, { root: true }).then((user) => {
          if (!user) {
            commit('setUser', []);
          } else {
            commit('setUser', user);
          }
          return user;
        });
      }
      return new Promise(resolve => resolve(state.user));
    },
  },
};
