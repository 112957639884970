/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */

export default {
  namespaced: true,
  state: {
    configsLoaded: false,
    configs: [],
    configNextUrl: null,
    ActiveConfig: {
      datasource: null,
      entity: null,
      uniqueIdField: null,
      nameField: null,
      aliasField: null,
    },
  },
  getters: {},
  actions: {
    update({ commit, dispatch }, data) {
      const vm = this;
      const url = `sync/${data.entity}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.put(url, data).then((response) => {
        commit('progress/removeProcessing', url, { root: true });
        dispatch('progress/setSuccessMessage', 'Successfully updated sync config', {
          root: true,
        });
        dispatch('list');
        return response;
      }, (response) => {
        let errorMessage = 'Error updating sync config';
        if (response && response.response && response.response.data.error.message) {
          errorMessage = response.response.data.error.message;
        }
        dispatch('progress/setErrorMessage', errorMessage, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return Promise.reject(errorMessage);
      });
    },
    list({ commit, dispatch }) {
      const vm = this;
      const url = 'sync';
      commit('setConfigsLoaded', false);
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        commit('setConfigs', data.data.items);
        commit('setConfigNextUrl', data.data.next);
        commit('setConfigsLoaded', true);
        if (data.data.next) {
          dispatch('listMore');
        }
        return data;
      }, error => error);
    },
    listMore({ commit, state, dispatch }) {
      const vm = this;
      if (state.configNextUrl) {
        commit('progress/setProcessing', state.configNextUrl, { root: true });
        return vm.$http.get(state.configNextUrl).then((data) => {
          commit('progress/removeProcessing', state.configNextUrl, { root: true });
          commit('setConfigNextUrl', data.data.next);
          commit('appendConfigs', data.data.items);
          commit('setConfigsLoaded', true);
          if (data.data.next) {
            dispatch('listMore');
          }
          return data;
        }, error => error);
      }
      return null;
    },
    get({ commit }, entity) {
      const vm = this;
      const url = `sync/${entity}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentConfig', data.data);
        return data;
      }, (error) => {
        commit('progress/removeProcessing', url, { root: true });
        return error.response;
      });
    },
  },
  mutations: {
    setConfigNextUrl(state, payload) {
      state.configNextUrl = payload;
    },
    setConfigs(state, payload) {
      state.configs = payload;
    },
    setConfigsLoaded(state, payload) {
      state.configsLoaded = payload;
    },
    appendConfigs(state, payload) {
      state.configs.push(...payload);
    },
    setCurrentConfig(state, payload) {
      state.ActiveConfig = payload;
    },
  },
};
