/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import alphabetizeByKey from '@/utilities/alphabetizeByKey';

export default {
  namespaced: true,
  state: {
    all: [],
  },
  getters: {},
  actions: {

    list({ commit, dispatch }) {
      const vm = this;
      const url = 'duplicates';
      const responseItems = [];
      commit('progress/setProcessing', url, { root: true });
      const getData = (pageUrl) => {
        vm.$http.get(pageUrl).then((response) => {
          responseItems.push(...response.data.items);
          commit('progress/removeProcessing', url, { root: true });
          commit('setDuplicates', responseItems);
          if (response.data.next) {
            return getData(response.data.next);
          }
          return responseItems;
        }, (error) => {
          dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
          commit('progress/removeProcessing', url, { root: true });
          return error.response.data.error.message;
        });
      };
      return getData(url);
    },

  },
  mutations: {
    setDuplicates(state, duplicates) {
      state.all = duplicates.sort(alphabetizeByKey.bind(null, { key: 'name', desc: false }));
    },
  },
};
