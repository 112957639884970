/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */

const getDataMutation = (afterId, mapped) => {
  if (mapped === null) {
    if (afterId) {
      return 'appendSuggestionData';
    }
    return 'setSuggestionData';
  } if (mapped === false) {
    if (afterId) {
      return 'appendUnmappedData';
    }
    return 'setUnmappedData';
  } if (mapped === true) {
    if (afterId) {
      return 'appendMappedData';
    }
    return 'setMappedData';
  } if (afterId) {
    return 'appendAllData';
  }
  return 'setAllData';
};
const getLoadedMutation = (mapped) => {
  if (mapped === null) {
    return 'setSuggestionDataLoaded';
  } if (mapped === false) {
    return 'setUnmappedDataLoaded';
  } if (mapped === true) {
    return 'setMappedDataLoaded';
  }
  return 'setAllDataLoaded';
};

export default {
  namespaced: true,
  state: {
    tablesLoaded: false,
    tables: [],
    suggestionsLoaded: false,
    suggestions: [],
    allData: [],
    mappedData: [],
    unmappedData: [],
    suggestionData: [],
    allDataLoaded: false,
    mappedDataLoaded: false,
    unmappedDataLoaded: false,
    suggestionDataLoaded: false,
    unsaved: [],
    tablesNextUrl: null,
    ActiveTable: {
      datasource: null,
      referenceFields: [],
      id: null,
      keyField: null,
      createdAt: null,
      createdBy: null,
      updatedAt: null,
      updatedBy: null,
    },
    totals: {
      all: 0,
      mapped: 0,
      unmapped: 0,
      suggestions: 0,
    },
  },
  getters: {
    formattedUnsavedData: state => state.unsaved.map((x) => {
      const item = {};
      item[state.ActiveTable.keyField] = x[state.ActiveTable.keyField];
      item.harmonization = x.harmonization;
      item.harmonization_name = x.harmonizationName;
      item.previousHarmonization = x.previousHarmonization;
      item.previousHarmonizationName = x.previousHarmonizationName;
      item.harmonization_source = x.harmonizationSource;
      item.supplementalSearchValues = x.supplementalSearchValues;
      return item;
    }),
  },
  actions: {
    create({ commit, dispatch }, data) {
      const vm = this;
      const url = `mappingtables/${data.datasource}/${data.entity}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url, data).then((response) => {
        commit('progress/removeProcessing', url, { root: true });
        dispatch('progress/setSuccessMessage', 'Successfully Created Mapping Table', {
          root: true,
        });
        return response;
      }, (response) => {
        let errorMessage = 'Error Creating Mapping Table';
        if (response && response.response && response.response.data.error.message) {
          errorMessage = response.response.data.error.message;
        }
        dispatch('progress/setErrorMessage', errorMessage, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return Promise.reject();
      });
    },
    update({ commit, dispatch }, data) {
      const vm = this;
      const url = `mappingtables/${data.datasource}/${data.entity}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.put(url, data.data).then((response) => {
        commit('progress/removeProcessing', url, { root: true });
        dispatch('progress/setSuccessMessage', 'Successfully Updated Mapping Table', {
          root: true,
        });
        return response;
      }, (response) => {
        let errorMessage = 'Error Updating Mapping Table';
        if (response && response.response && response.response.data.error.message) {
          errorMessage = response.response.data.error.message;
        }
        dispatch('progress/setErrorMessage', errorMessage, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return Promise.reject();
      });
    },
    delete({ commit, dispatch }, data) {
      const vm = this;
      const url = `mappingtables/${data.datasource}/${data.entity}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.delete(url).then((response) => {
        commit('progress/removeProcessing', url, { root: true });
        dispatch('progress/setSuccessMessage', 'Mapping table is being deleted', {
          root: true,
        });
        return response;
      }, (response) => {
        let errorMessage = 'Error Deleting Mapping Table';
        if (response && response.response && response.response.data.error.message) {
          errorMessage = response.response.data.error.message;
        }
        dispatch('progress/setErrorMessage', errorMessage, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return Promise.reject(errorMessage);
      });
    },
    list({ commit }, { datasource }) {
      const vm = this;
      let url = 'mappingtables';
      if (datasource) {
        url = `${url}/${datasource}`;
      }
      commit('setMappingTablesLoaded', false);
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        commit('setMappingTables', data.data.items);
        commit('setTablesNextUrl', data.data.next);
        commit('setMappingTablesLoaded', true);
        return data;
      }, error => error);
    },
    listMore({ commit, state }) {
      const vm = this;
      if (state.tablesNextUrl) {
        commit('progress/setProcessing', state.tablesNextUrl, { root: true });
        return vm.$http.get(state.tablesNextUrl).then((data) => {
          commit('progress/removeProcessing', state.tablesNextUrl, { root: true });
          commit('setTablesNextUrl', data.data.next);
          commit('appendMappingTables', data.data.items);
          commit('setMappingTablesLoaded', true);
          return data;
        }, error => error);
      }
      return null;
    },
    get({ commit }, { datasource, entity }) {
      const vm = this;
      const url = `mappingtables/${datasource}/${entity}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        commit('setCurrentTable', data.data);
        return data;
      }, (error) => {
        commit('progress/removeProcessing', url, { root: true });
        return error.response;
      });
    },
    getData({ commit }, { datasource, entity, offset, mapped }) {
      const vm = this;
      let url = `mappingtables/${datasource}/${entity}/data`;
      const dataMutation = getDataMutation(offset, mapped);
      const loadedMutation = getLoadedMutation(mapped);
      if (offset || mapped !== undefined) {
        url = `${url}?`;
      }
      if (offset) {
        url = `${url}offset=${offset}&`;
      } else {
        commit(loadedMutation, false);
      }
      if (mapped !== undefined) {
        url = `${url}mapped=${mapped}`;
      }
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        commit(dataMutation, { items: data.data.items.map((x) => {
          const item = {
            ...x,
            harmonizationName: x.harmonization_name,
          };
          delete item.harmonization_name;
          return item;
        }),
        total: data.data.total });
        commit(loadedMutation, true);
        return data;
      }, error => error);
    },
    getSuggestions({ commit }, { datasource, entity, offset }) {
      const vm = this;
      let url = `mappingtables/${datasource}/${entity}/suggestions`;
      const dataMutation = getDataMutation(offset, null);
      const loadedMutation = getLoadedMutation(null);
      if (offset) {
        url = `${url}?`;
      }
      if (offset) {
        url = `${url}offset=${offset}&`;
      } else {
        commit(loadedMutation, false);
      }
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((data) => {
        commit('progress/removeProcessing', url, { root: true });
        commit(dataMutation, {
          items: data.data.items.map((x) => {
            const item = {
              ...x,
              harmonizationName: x.harmonization_name,
            };
            delete item.harmonization_name;
            return item;
          }),
          total: data.data.total,
        });
        commit(loadedMutation, true);
        return data;
      }, error => error);
    },
    save({ commit, dispatch, getters }, { datasource, entity }) {
      const vm = this;
      const url = `mappingtables/${datasource}/${entity}/data`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url, getters.formattedUnsavedData).then((response) => {
        commit('progress/removeProcessing', url, { root: true });
        dispatch('progress/setSuccessMessage', 'Successfully Saved Mappings', { root: true });
        commit('setUnsavedData', []);
        return response;
      }, (response) => {
        let errorMessage = 'Error Saving Data';
        if (response && response.response && response.response.data.error.message) {
          errorMessage = response.response.data.error.message;
        }
        dispatch('progress/setErrorMessage', errorMessage, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return Promise.reject();
      });
    },
  },
  mutations: {
    setTablesNextUrl(state, payload) {
      state.tablesNextUrl = payload;
    },
    setMappingTables(state, payload) {
      state.tables = payload;
    },
    setMappingTablesLoaded(state, payload) {
      state.tablesLoaded = payload;
    },
    appendMappingTables(state, payload) {
      state.tables.push(...payload);
    },
    setSuggestions(state, payload) {
      state.suggestions = payload;
    },
    setSuggestionsLoaded(state, payload) {
      state.suggestionsLoaded = payload;
    },
    appendSuggestions(state, payload) {
      state.suggestions.push(...payload);
    },
    setAllData(state, payload) {
      state.allData = payload.items;
      state.totals.all = payload.total;
    },
    setAllDataLoaded(state, payload) {
      state.allDataLoaded = payload;
    },
    appendAllData(state, payload) {
      state.allData.push(...payload.items);
    },
    setUnmappedData(state, payload) {
      state.unmappedData = payload.items;
      state.totals.unmapped = payload.total;
    },
    setSuggestionData(state, payload) {
      state.suggestionData = payload.items;
      state.totals.suggestions = payload.total;
    },
    setUnmappedDataLoaded(state, payload) {
      state.unmappedDataLoaded = payload;
    },
    setSuggestionDataLoaded(state, payload) {
      state.suggestionDataLoaded = payload;
    },
    appendUnmappedData(state, payload) {
      state.unmappedData.push(...payload.items);
    },
    appendSuggestionData(state, payload) {
      state.suggestionData.push(...payload.items);
    },
    setMappedData(state, payload) {
      state.mappedData = payload.items;
      state.totals.mapped = payload.total;
    },
    setMappedDataLoaded(state, payload) {
      state.mappedDataLoaded = payload;
    },
    appendMappedData(state, payload) {
      state.mappedData.push(...payload.items);
    },
    setCurrentTable(state, payload) {
      state.ActiveTable = payload;
    },
    setUnsavedData(state, payload) {
      state.unsaved = payload;
    },
    undo(state, rowId) {
      if (state.allData.find(x => x[state.ActiveTable.keyField] === rowId)) {
        state.allData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonization = null;
        state.allData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonizationName = null;
      }
      if (state.unmappedData.find(x => x[state.ActiveTable.keyField] === rowId)) {
        state.unmappedData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonization = null;
        state.unmappedData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonizationName = null;
      }
      if (state.mappedData.find(x => x[state.ActiveTable.keyField] === rowId)) {
        state.mappedData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonization = null;
        state.mappedData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonizationName = null;
      }
      if (state.unsaved.find(x => x[state.ActiveTable.keyField] === rowId)) {
        const index = state.unsaved.findIndex(x => x[state.ActiveTable.keyField] === rowId);
        state.unsaved.splice(index, 1);
      }
    },
    setMapping(state, {
      rowId,
      harmonization,
      harmonizationName,
      row,
      previousHarmonization,
      previousHarmonizationName,
      harmonizationSource,
      supplementalSearchValues,
    }) {
      if (state.allData.find(x => x[state.ActiveTable.keyField] === rowId)) {
        state.allData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonization = harmonization;
        state.allData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonizationName = harmonizationName;
        state.allData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonizationSource = harmonizationSource;
        state.allData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).supplementalSearchValues = supplementalSearchValues;
      }
      if (state.unmappedData.find(x => x[state.ActiveTable.keyField] === rowId)) {
        state.unmappedData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonization = harmonization;
        state.unmappedData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonizationName = harmonizationName;
        state.unmappedData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonizationSource = harmonizationSource;
        state.unmappedData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).supplementalSearchValues = supplementalSearchValues;
      }
      if (state.mappedData.find(x => x[state.ActiveTable.keyField] === rowId)) {
        state.mappedData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonization = harmonization;
        state.mappedData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonizationName = harmonizationName;
        state.mappedData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonizationSource = harmonizationSource;
        state.mappedData.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).supplementalSearchValues = supplementalSearchValues;
      }
      if (state.unsaved.find(x => x[state.ActiveTable.keyField] === rowId)) {
        state.unsaved.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonization = harmonization;
        state.unsaved.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonizationName = harmonizationName;
        state.unsaved.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).previousHarmonization = previousHarmonization;
        state.unsaved.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).previousHarmonizationName = previousHarmonizationName;
        state.unsaved.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).harmonizationSource = harmonizationSource;
        state.unsaved.find(x => (
          x[state.ActiveTable.keyField] === rowId
        )).supplementalSearchValues = supplementalSearchValues;
      } else {
        state.unsaved.push({
          ...row,
          harmonization,
          harmonizationName,
          previousHarmonization,
          previousHarmonizationName,
          harmonizationSource,
          supplementalSearchValues,
        });
      }
    },
  },
};
