import store from '@/store';
import auth from '@/services/auth';

class SecurityCheck {
  constructor(to) {
    /**
     * SecurityCheck class
     * @param {Object} to - The route object
     * @returns {SecurityCheck} - An instance of the SecurityCheck class
    */
    this.requiresAuth = to.meta && to.meta.auth;
    this.permission = to.meta.auth?.permission;
    this.allowAnonymous = to.meta && to.meta.allowAnonymous;
    this.allowNonOnboarded = to.meta && to.meta.allowNonOnboarded;
    this.isAuthenticated = (
      store.getters.refreshToken
      && store.getters.isAuthenticated
      && Math.round((new Date()).getTime() / 1000) < store.getters.expires
    );
    this.isOnboardedUser = store.getters.isOnboardedUser;
    this.authExpires = store.getters.expires;
    this.isCallbackPage = window.location.pathname === '/callback';
  }

  authExpired() {
    /**
     * Check if the user's authentication has expired
     * @returns {Boolean} - True if the user's authentication has expired
    */
    return Math.round((new Date()).getTime() / 1000) > this.authExpires;
  }

  authenticate() {
    /**
     * Authenticate the user
     * @returns {Promise} - A promise that resolves to true if the user is authenticated and false if the user is unable to be authenticated
    */
    if (this.isAuthenticated) {
      return Promise.resolve(true);
    }
    if (store.getters.refreshToken) {
      return store.dispatch('refreshToken').then((token) => {
        if (!token) {
          store.commit('setRequestedRoute', window.location.pathname);
          auth.authenticate('ONEWP-OKTA');
        }
        return Promise.resolve(true);
      });
    }
    if (this.isCallbackPage) {
      return Promise.resolve(true);
    }
    store.commit('setRequestedRoute', window.location.pathname);
    auth.authenticate('ONEWP-OKTA');
    return Promise.resolve(false);
  }

  authorize() {
    /**
     * Authorize the user
     * @returns {Promise} - A promise that resolves to true if the user is authorized and false if the user is unauthorized
    */
    if (this.isCallbackPage) {
      return Promise.resolve(true);
    }
    return this.updateUserInfo().then(() => {
      if (!this.isOnboardedUser) {
        return Promise.resolve(this.handleNonOnboardedUser());
      }
      if (this.requiresAuth) {
        return Promise.resolve(this.checkRoutePermission());
      }
      return Promise.resolve(true);
    });
  }

  handleNonOnboardedUser() {
    /**
     * Handle a non-onboarded user
     */
    if (this.allowNonOnboarded) {
      return true;
    }
    return false;
  }

  checkRoutePermission() {
    const hasPermission = perm => store.getters['user/permissions'].map(x => x.name).indexOf(perm) > -1;
    if (hasPermission(this.permission)) {
      return true;
    }
    return false;
  }

  updateUserInfo() {
    /**
     * Gets the user's information from Protego
     * @returns {Promise} - A promise that resolves to true if the user's information was updated
    */
    if (!this.allowAnonymous) {
      return store.dispatch('getUser').then(() => {
        this.isAuthenticated = (
          store.getters.refreshToken
          && store.getters.isAuthenticated
          && Math.round((new Date()).getTime() / 1000) < store.getters.expires
        );
        this.isOnboardedUser = store.getters.isOnboardedUser;
        return true;
      }, () => true);
    }
    return new Promise(resolve => resolve(false));
  }
}

export default SecurityCheck;
