/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import defaultRecord from '@/models/record';

const buildRecordsURL = (entityName, filter, filterByRecord, page, count, detailed) => {
  let detailedParam = detailed;
  if (detailedParam === undefined) {
    detailedParam = true;
  }
  const offset = (page - 1) * count;
  let url = `entities/${entityName}/records?detailed=${detailedParam}&include_temporary=true&count=${count}&offset=${offset}`;
  if (filter) {
    url = `${url}&name=${filter}`;
  } else {
    url = `${url}&order_by=name`;
  }
  if (filterByRecord) {
    let filterString = filterByRecord;
    if (Array.isArray(filterString)) {
      filterString = filterString.join();
    }
    url = `${url}active_records=${filterString}`;
  }
  return url;
};

export default {
  namespaced: true,
  state: {
    Record: {},
    Entities: {},
    Fetching: [],
    QuarantinedRecords: [],
    ResolvedRecords: [],
    Filter: null,
    uploadData: {},
    Harmonizations: [],
  },
  getters: {
    entityRecords: (state) => {
      const dict = {};
      Object.keys(state.Entities).forEach((entity) => {
        dict[entity] = state.Entities[entity].all;
      });
      return dict;
    },
    /* searchRecords: (state) => {
      const dict = {};
      Object.keys(state.Entities).forEach((entity) => {
        const records = [];
        for (let i = 0; i < state.Entities[entity].all.length; i += 1) {
          const x = state.Entities[entity].all[i];
          const matchingRecord = state.Entities[entity].all.find(y => y.id === x.id);
          if (matchingRecord) {
            records.push({
              ...matchingRecord,
              highlights: x.highlights,
              score: x.score,
              order: i,
              _showDetails: true,
            });
          } else {
            records.push({
              id: x.id,
              name: x.name,
              highlights: x.highlights,
              score: x.score,
              order: i,
              _showDetails: true,
            });
          }
        }
        dict[entity] = records;
      });
      return dict;
    }, */
  },
  actions: {
    listMultiple({ dispatch }, entities) {
      const promises = [];
      for (let i = 0; i < entities.length; i += 1) {
        promises.push(dispatch('list', entities[i]));
      }
      return Promise.all(promises);
    },

    search({ commit }, input) {
      const vm = this;
      const count = input.count || 1000;
      const url = `entities/${input.entity}/search?query=${encodeURIComponent(input.query)}&advanced=${input.advanced}&count=${count}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((response) => {
        const records = [];
        if (input.advanced) {
          records.push(...response.data.map((x, y) => ({
            ...x,
            order: y,
            _showDetails: true,
          })));
        } else if (response.data && response.data) {
          records.push(response.data);
        }
        commit('resetFilteredRecords', input.entity);
        commit('setFilteredEntityRecords', {
          entity: input.entity,
          data: records,
        });
        commit('progress/removeProcessing', url, { root: true });
        return records;
      });
    },

    list({ state, rootState, commit, dispatch }, entity) {
      const vm = this;
      const url = buildRecordsURL(
        entity.name,
        entity.filter,
        entity.filterByRecord,
        1,
        1000,
        entity.detailed,
      );
      const fetchingListKey = `${entity.name}-${entity.filter}`;
      const invalidateCache = (
        state.Entities[entity.name]
        && state.Entities[entity.name].invalidateCache
      );
      const request = {};
      if (invalidateCache) {
        request.headers = { 'Cache-Control': 'max-age=0' };
      } else {
        request.headers = { 'Cache-Control': 'max-age=600' };
      }
      if (state.Fetching.indexOf(fetchingListKey) > -1) {
        return new Promise(resolve => resolve([]));
      }
      commit('setFetching', fetchingListKey);
      if (entity.filter) {
        commit('resetFilteredRecords', entity.name);
      } else {
        commit('resetAllRecords', entity.name);
      }
      if (!entity.hideSpinner) {
        commit('progress/setProcessing', url, { root: true });
      }
      const getData = (pageUrl, append) => vm.$http.get(pageUrl, request).then((responseData) => {
        const records = responseData.data.items;
        const nextPage = responseData.data.next;
        let responseMutation = entity.filter ? 'setFilteredEntityRecords' : 'setEntityRecords';
        if (append) {
          responseMutation = entity.filter ? 'setFilteredEntityRecords' : 'appendEntityRecords';
        }
        commit(responseMutation, { entity: entity.name, data: records });
        if (!entity.hideSpinner) {
          commit('progress/removeProcessing', url, { root: true });
        }
        commit('removeFetching', fetchingListKey);
        if (!nextPage) {
          commit('setInvalidateCacheFlag', { entity: entity.name, invalidate: false });
          return records;
        }
        return getData(nextPage, true);
      });
      if (rootState.entities.Entities.length) {
        return getData(url, false);
      }
      return dispatch('entities/list', null, { root: true }).then(() => getData(url, false));
    },

    getFirstPage({ state }, entity) {
      const vm = this;
      const url = `${buildRecordsURL(entity.name, entity.filter, entity.filterByRecord, 1, 1000, entity.detailed)}`;
      const invalidateCache = (
        state.Entities[entity.name]
        && state.Entities[entity.name].invalidateCache
      );
      const request = {};
      if (invalidateCache) {
        request.headers = { 'Cache-Control': 'max-age=0' };
      } else {
        request.headers = { 'Cache-Control': 'max-age=600' };
      }
      return vm.$http.get(url, request).then(response => response.data, error => error);
    },

    getQuarantinedRecords({ commit, dispatch }) {
      const vm = this;
      let url = 'quarantine/records';
      let reset = true;
      commit('progress/setProcessing', url, { root: true });
      const getData = () => vm.$http.get(url).then((response) => {
        commit('setQuarantinedRecords', {
          reset,
          records: response.data.items,
        });
        commit('progress/removeProcessing', url, { root: true });
        if (response.data.next) {
          url = response.data.next;
          reset = false;
          getData();
        }
        return response.data.items;
      }, (error) => {
        dispatch('progress/setErrorMessage', 'Error fetching records', { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error;
      });
      return getData();
    },

    getResolvedRecords({ commit, dispatch }) {
      const vm = this;
      let url = 'quarantine/resolved';
      let reset = true;
      commit('progress/setProcessing', url, { root: true });
      const getData = () => vm.$http.get(url).then((response) => {
        commit('setResolvedRecords', {
          reset,
          records: response.data.items,
        });
        commit('progress/removeProcessing', url, { root: true });
        if (response.data.next) {
          url = response.data.next;
          reset = false;
          getData();
        }
        return response.data.items;
      }, (error) => {
        dispatch('progress/setErrorMessage', 'Error fetching records', { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error;
      });
      return getData();
    },

    get({ commit }, data) {
      const vm = this;
      const url = `entities/${data.entity}/records/${data.id}`;
      commit('progress/setProcessing', url, { root: true });
      let request = {};
      if (data.forceRefresh) {
        request = { headers: { 'Cache-Control': 'max-age=0' } };
      }
      return vm.$http.get(url, request).then((response) => {
        if (!data.noSet) {
          commit('setRecord', response.data);
        }
        commit('progress/removeProcessing', url, { root: true });
        return response.data;
      });
    },

    getHarmonizations({ commit }, data) {
      const vm = this;
      const url = `entities/${data.entity}/records/${data.id}/harmonizations`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.get(url).then((response) => {
        commit('setRecordHarmonizations', response.data);
        commit('progress/removeProcessing', url, { root: true });
        return response.data;
      });
    },

    handleRecordResponse({ dispatch, commit }, config) {
      dispatch('progress/setSuccessMessage', config.successString, { root: true });
      dispatch('get', {
        entity: config.response.data.entity,
        id: config.response.data.id,
        forceRefresh: true,
      });
      commit('setInvalidateCacheFlag', { entity: config.entity, invalidate: true });
      commit('progress/removeProcessing', config.url, { root: true });
      return config.response.data;
    },

    handleDeletedResponse({ dispatch, commit }, config) {
      dispatch('progress/setSuccessMessage', config.successString, { root: true });
      commit('removeEntityRecord', { entity: config.entity, id: config.response.data.id });
      commit('progress/removeProcessing', config.url, { root: true });
      commit('setInvalidateCacheFlag', { entity: config.entity, invalidate: true });
      return config.response.data;
    },

    handleErrorResponse({ dispatch, commit }, config) {
      commit('progress/removeProcessing', config.url, { root: true });
      let error = null;
      if (config.response.error) {
        error = config.response.error.message;
      }
      if (error) {
        dispatch('progress/setErrorMessage', error, { root: true });
        return null;
      }
      dispatch('progress/setErrorMessage', config.errorString, { root: true });
      return null;
    },

    update({ dispatch, commit }, data) {
      const vm = this;
      const url = `entities/${data.entity}/records/${data.id}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.put(url, data).then(response => dispatch('handleRecordResponse', {
        response,
        successString: 'Successfully updated record',
        entity: data.entity,
        url,
      }), response => dispatch('handleErrorResponse', {
        response: response.response.data,
        errorString: 'Update failed with the following error:',
        url,
      }));
    },

    updateQuarantine({ dispatch, commit, rootGetters }, payload) {
      const vm = this;
      const data = { ...payload };
      data.resolution.user = rootGetters.emailAddress;
      const url = `entities/${data.entity}/records/${data.record}/resolution`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url, data.resolution).then(response => dispatch('handleRecordResponse', {
        response,
        successString: 'Successfully updated record',
        entity: data.entity,
        url,
      }), response => dispatch('handleErrorResponse', {
        response: response.response.data,
        errorString: 'Update failed with the following error:',
        url,
      }));
    },

    mergeRecords({ dispatch, commit, rootGetters }, payload) {
      const vm = this;
      const data = { ...payload };
      data.form.user = rootGetters.emailAddress;
      const url = `entities/${data.entity}/records/${data.record}/duplicates`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url, data.form).then(response => dispatch('handleRecordResponse', {
        response,
        successString: 'Successfully updated record',
        entity: data.entity,
        url,
      }), response => dispatch('handleErrorResponse', {
        response: response.response.data,
        errorString: 'Update failed with the following error:',
        url,
      }));
    },

    create({ dispatch, commit }, data) {
      const vm = this;
      const url = `entities/${data.entity}/records`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url, data).then(response => dispatch('handleRecordResponse', {
        response,
        successString: 'Successfully created record',
        entity: data.entity,
        url,
      }), response => dispatch('handleErrorResponse', {
        response: response.response.data,
        errorString: 'Create failed with the following error:',
        url,
      }));
    },

    delete({ dispatch, commit }, data) {
      const vm = this;
      const url = `entities/${data.entity}/records/${data.id}`;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.delete(url, data).then(response => dispatch('handleDeletedResponse', {
        response,
        successString: 'Successfully deleted record',
        entity: data.entity,
        url,
      }), response => dispatch('handleErrorResponse', {
        response: response.response.data,
        errorString: 'Update failed with the following error:',
        url,
      }));
    },

    purgeRejected({ dispatch, commit }, data) {
      const vm = this;
      const url = 'quarantine/resolved';
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.patch(url, data).then((response) => {
        dispatch('progress/setSuccessMessage', 'Successfully deleted records', { root: true });
        commit('progress/removeProcessing', url, { root: true });
        dispatch('getResolvedRecords');
        return response.data;
      }, (response) => {
        dispatch('progress/setErrorMessage', response.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        dispatch('getResolvedRecords');
        return response.data;
      });
    },

    generateUploadUrl({ commit, dispatch }, url) {
      const vm = this;
      commit('progress/setProcessing', url, { root: true });
      return vm.$http.post(url).then((response) => {
        const uploadData = response.data;
        commit('progress/removeProcessing', url, { root: true });
        commit('setUploadData', uploadData);
        return uploadData;
      }, (error) => {
        dispatch('progress/setErrorMessage', error.response.data.error.message, { root: true });
        commit('progress/removeProcessing', url, { root: true });
        return error.response.data.error.message;
      });
    },

  },
  mutations: {
    setUploadData(state, uploadData) {
      state.uploadData = uploadData;
    },
    resetEntityRecords(state, entity) {
      if (!state.Entities[entity]) {
        state.Entities[entity] = {};
        // eslint-disable-next-line no-param-reassign
        state.Entities[entity].all = [];
        state.Entities[entity].filtered = [];
      } else {
        state.Entities[entity].all = [];
        state.Entities[entity].filtered = [];
      }
    },
    resetFilteredRecords(state, entity) {
      if (!state.Entities[entity]) {
        state.Entities[entity] = {};
        // eslint-disable-next-line no-param-reassign
        state.Entities[entity].all = [];
        state.Entities[entity].filtered = [];
      } else {
        state.Entities[entity].filtered = [];
      }
    },
    resetAllRecords(state, entity) {
      if (!state.Entities[entity]) {
        state.Entities[entity] = {};
        // eslint-disable-next-line no-param-reassign
        state.Entities[entity].all = [];
        state.Entities[entity].filtered = [];
      } else {
        state.Entities[entity].all = [];
      }
    },

    removeEntityRecord(state, payload) {
      if (state.Entities[payload.entity] && state.Entities[payload.entity].all) {
        const index = state.Entities[payload.entity].all.findIndex(x => x.id === payload.id);
        state.Entities[payload.entity].all.splice(index, 1);
      }
    },

    setEntityRecords(state, payload) {
      if (!state.Entities[payload.entity]) {
        state.Entities[payload.entity] = {};
      }
      if (!state.Entities[payload.entity].all) {
        state.Entities[payload.entity].all = payload.data;
      } else {
        // state.Entities[payload.entity].all.push(...payload.data);
        state.Entities[payload.entity].all = payload.data;
      }
    },

    appendEntityRecords(state, payload) {
      state.Entities[payload.entity].all.push(...payload.data);
    },

    setInvalidateCacheFlag(state, payload) {
      if (!state.Entities[payload.entity]) {
        state.Entities[payload.entity] = {};
      }
      if (!state.Entities[payload.entity].invalidateCache) {
        state.Entities[payload.entity].invalidateCache = payload.invalidate;
      } else {
        state.Entities[payload.entity].invalidateCache = payload.invalidate;
      }
    },

    setFilteredEntityRecords(state, payload) {
      if (!state.Entities[payload.entity]) {
        state.Entities[payload.entity] = {};
      }
      if (!state.Entities[payload.entity].filtered) {
        state.Entities[payload.entity].filtered = payload.data;
      } else {
        state.Entities[payload.entity].filtered.push(...payload.data);
      }
    },

    setQuarantinedRecords(state, payload) {
      if (payload.reset) {
        state.QuarantinedRecords = payload.records;
      } else {
        state.QuarantinedRecords.push(...payload.records);
      }
    },

    setFilter(state, payload) {
      state.Filter = payload;
    },

    setResolvedRecords(state, payload) {
      if (payload.reset) {
        state.ResolvedRecords = payload.records;
      } else {
        state.ResolvedRecords.push(...payload.records);
      }
    },

    setRecord(state, record) {
      const data = { ...record };
      for (let i = 0; i < Object.keys(data.fields).length; i += 1) {
        const fieldName = Object.keys(data.fields)[i];
        if (data.fields[fieldName] && Array.isArray(data.fields[fieldName])) {
          data.fields[fieldName] = data.fields[fieldName].map((x) => {
            if (x.email) {
              return x.email;
            }
            return x;
          });
        } else if (data.fields[fieldName] && data.fields[fieldName].email) {
          data.fields[fieldName] = data.fields[fieldName].email;
        }
      }
      state.Record = data;
    },

    setRecordHarmonizations(state, payload) {
      state.Harmonizations = payload;
    },

    resetRecord(state) {
      state.Record = { ...defaultRecord };
    },

    setFetching(state, entity) {
      state.Fetching.push(entity);
    },

    removeFetching(state, entity) {
      state.Fetching.splice(state.Fetching.indexOf(entity), 1);
    },
  },
};
